import React from "react";
import { NodeProps } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import DebugNode from "./types/DebugNode";
import { NodeData } from "../store/store";

function RPCrequest({ selected, id, data }: NodeProps<NodeData>) {

    const { t } = useTranslation();

    const label = t('rpc.request.name')
    return (
        <DebugNode label={label} selected={selected} />
    );
}

export default RPCrequest;
