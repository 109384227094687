import React, { useEffect, useState } from "react";
import {
  Circle,
} from "@chakra-ui/react";

interface Lamp {
  isWork?: boolean;
  colors?: string[];
}

function Lamp(props: Lamp) {
    const { isWork = false, colors } = props;
    const resColors = colors || ['#19ff19', 'red']
    return (
     <Circle border={"1px"} transition={'all 0.1s ease'} bgColor={isWork ? resColors[0] : resColors[1]} size={"15px"}></Circle>
    )
}
export default Lamp;
