import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ReactJson from "react-json-view";

import {
  darkOceanEdited,
  invertedOceanEdited,
} from "../../styles/componentsStyles";
import { useTranslation } from "react-i18next";
import useStore, { NodeData } from "../../store/store";
import InputNode from "../types/InputNode";


function BACnetRead({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [settings, setSettings] = useState<string>(
      data.settings !== undefined && typeof data.settings == "string"
          ? data.settings
          : ""
  );

  const [file, setFile] = useState<File | null | undefined>(undefined);
  const [csv, setCSV] = useState<string[][]>([]);
  const jsonTheme = useColorModeValue(invertedOceanEdited, darkOceanEdited);

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = settings
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation()

  const label = t('bacnet.rp.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('bacnet.rp.head')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>{t('bacnet.rp.props.0')}</FormLabel>
                <Input
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      let reader = new FileReader();
                      reader.onload = () => {
                        const tmp = reader.result!.toString();
                        setSettings(tmp);
                        const rows = tmp.split("\n");
                        let elements: string[][] = [];
                        rows.forEach((row) => {
                          const columns = row.split(";");
                          elements.push(columns);
                        });
                        setCSV(elements);
                      };
                      reader.readAsText(e.target.files?.item(0)!);
                    }}
                />
              </FormControl>

            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={onSave} mr={3}>
                {t('Save')}
              </Button>
              <Button onClick={onClose}>{t('Cancel')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </InputNode>
  );
}

export default BACnetRead;
