import React, { useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputLeftAddon,
  InputGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import Block from "../components/Block";

function WS({ data, selected }: { data: any; selected: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [host, setHost] = React.useState("fw.4iot.pro");
  const [port, setPort] = React.useState("9933");
  const [way, setWay] = React.useState("/ws");

  const onSave = () => {
    data.set(
      {
        host,
        port,
        way,
        // replace,
        // authorization,
        // content,
        // accept,
        // protection,
      },
      data.id
    );
    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  return (
    <Block variant="output" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">WS out</label>
        <Button
          variant="outline"
          size="sm"
          rightIcon={<VscSettings />}
          onClick={onOpen}
        >
          Settings
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>WS settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Host</FormLabel>
              <InputGroup>
                <InputLeftAddon children="ws://" />
                <Input
                  type="text"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                />
              </InputGroup>
              <FormLabel>Port</FormLabel>
              <Input
                type="text"
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
              <FormLabel>Way</FormLabel>
              <Input
                type="text"
                value={way}
                onChange={(e) => setWay(e.target.value)}
              />
              {/* <FormLabel>Replace</FormLabel>
                            <Input type="text" value={replace} onChange={(e)=>setReplace(e.target.value)}/> */}
              {/* <FormLabel>Authorization</FormLabel>
                            <Input type="text" value={authorization} onChange={(e)=>setAuthorization(e.target.value)}/>
                            <FormLabel>Content</FormLabel>
                            <Input type="text" value={content} onChange={(e)=>setContent(e.target.value)}/>
                            <FormLabel>Accept</FormLabel>
                            <Input type="tetxt" value={accept} onChange={(e)=>setAccept(e.target.value)}/>
                            <FormLabel>Protection</FormLabel>
                            <Input type="text" value={protection} onChange={(e)=>setProtection(e.target.value)}/> */}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default WS;
