import React from "react";
import { NodeProps } from "react-flow-renderer";
import { NodeData } from "../store/store";
import { useTranslation } from "react-i18next";
import DebugNode from "./types/DebugNode";

function ConsoleLoger({ selected }: NodeProps<NodeData>) {
  const { t } = useTranslation()

  const label = t('debug.console')

  return (
    <DebugNode label={label} selected={selected} />
  );
}

export default ConsoleLoger;
