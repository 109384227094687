import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { Checkbox, IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import { MdEmail } from "react-icons/md";

function ToEmail({ data, selected }: { data: any; selected: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [protocol, setProtocol] = React.useState("SMTP");
  const [host, setHost] = React.useState("localhost");
  const [port, setPort] = React.useState(25);
  const [timeout, setTimeout] = React.useState(1000);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="output" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">To Email</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<MdEmail />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Protocol</FormLabel>
              <Select
                name="type"
                value={protocol}
                onChange={(e) => setProtocol(e.target.value)}
              >
                <option value="SMTP">SMTP</option>
                <option value="IMAP">IMAP</option>
                <option value="POP3">POP3</option>
              </Select>
            </FormControl>
            <HStack>
              <FormControl>
                <FormLabel>Host</FormLabel>
                <Input
                  type="text"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Port</FormLabel>
                <Input
                  type="number"
                  value={port}
                  onChange={(e) => setPort(parseInt(e.target.value))}
                />
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel>Timeout (ms)</FormLabel>
              <Input
                type="number"
                value={timeout}
                onChange={(e) => setTimeout(parseInt(e.target.value))}
              />
            </FormControl>
            <Checkbox>Enable TLS</Checkbox>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default ToEmail;
