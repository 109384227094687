import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../../store/store";
import OutputNode from "../types/OutputNode";
import { useTranslation } from "react-i18next";

function RESToutput({ selected, id }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [uri, setUri] = useState(
    "http://scada.4iot.pro/api/fw_add_journal_event"
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          uri
        }
      }
      return node
    })
    )
    setJson(inst.toObject())

    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t("http.out.name")

  return (
    <OutputNode selected={selected} label={label} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('http.out.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('http.out.props.0')}</FormLabel>
              <InputGroup>
                <Input
                  type="text"
                  value={uri}
                  onChange={(e) => setUri(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </OutputNode>
  );
}

export default RESToutput;
