import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import {IconButton} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Checkbox
} from "@chakra-ui/react";
import { MdAddCircleOutline } from "react-icons/md";

function AssignToCustomer({ data, selected }: { data: any, selected: boolean }) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = React.useState("Customer test")
  const [time, setTime] = React.useState(300)

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Assign to customer</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<MdAddCircleOutline />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign to customer settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Customer name pattern</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <Checkbox> Create new customer if not exists</Checkbox>
            <FormControl>
              <FormLabel>Customer cache expiration time (sec)</FormLabel>
              <Input
                type="number"
                value={time}
                onChange={(e) => setTime(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle type="target" className="custom-handle" position={Position.Left} id="0" />
      <Handle type="source" className="custom-handle" position={Position.Right} id="0" />
    </Block>
  );
}

export default AssignToCustomer;
