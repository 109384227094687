import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { BsBellSlashFill } from "react-icons/bs";

function ClearAlarm({ data, selected }: { data: any; selected: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState("General Alarm");
  const [script, setScript] = React.useState(
    "var details = {}\nif(metadata.prevAlarmDetails){\n  details = JSON.parse(msg.metadata.prevAlarmDetails)\n}\nreturn details"
  );

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Clear alarm</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<BsBellSlashFill />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Clear alarm settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Alarm type</FormLabel>
              <Input
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
            </FormControl>
            <Text mb="8px">Alarm details builder:</Text>
            <Text mb="8px">function(msg: object)</Text>
            <Textarea
              value={script}
              onChange={(e) => setScript(e.target.value)}
              size="sm"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default ClearAlarm;
