import {
  InfoIcon,
  MinusIcon,
  MoonIcon,
  SunIcon,
  UpDownIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import {
  ButtonGroup,
  Stack,
  IconButton,
  Image,
  useColorMode,
  Text,
  Switch,
  Flex,
  Spacer,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import logo from "../images/promuc_blue_logo.svg";
import useStore from "../store/store";
import { useTranslation } from "react-i18next";

interface FullScreenDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

interface FullScreenDocument extends Document {
  documentElement: FullScreenDocumentElement;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

function SidebarHeader({ onModalOpen }: { onModalOpen: () => void }) {
  const { t, i18n } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const [fullScreen, setFullScreen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const blockDisabled = useStore((state) => state.blockDisabled);
  const API = useStore((state) => state.API);
  const WS = useStore((state) => state.WS);

  const shouldBlockDisabled = useStore((state) => state.shouldBlockDisabled);
  const setAPI = useStore((state) => state.setAPI);
  const setWS = useStore((state) => state.setWS);

  const [api, set_api] = useState(API);
  const [ws, set_ws] = useState(WS);
  const [blDisabled, setBlDisabled] = useState(blockDisabled);
  const [lang, setLang] = useState(i18n.language)

  const doc = document as FullScreenDocument;

  const openFullScreen = () => {
    const elem = doc.documentElement;
    setFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  };

  const onSave = () => {
    setAPI(api);
    console.log("API изменён на: " + api);
    setWS(ws);
    console.log("WS изменён на: " + ws);
    shouldBlockDisabled(blDisabled);
    i18n.changeLanguage(lang)
    onClose();
  };

  return (
    <Stack spacing={5}>
      <Image height="64px" src={logo} alt="PromUC Rule Engine" />
      <ButtonGroup
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {fullScreen ? (
          <IconButton
            icon={<MinusIcon />}
            onClick={closeFullScreen}
            aria-label={""}
          />
        ) : (
          <IconButton
            icon={<UpDownIcon />}
            onClick={openFullScreen}
            aria-label={""}
          />
        )}
        <IconButton
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          aria-label={""}
        />
        <IconButton icon={<SettingsIcon />} aria-label={""} onClick={onOpen} />
        <IconButton icon={<InfoIcon />} aria-label={""} onClick={onModalOpen} />
      </ButtonGroup>
      <Text align={"center"}>RuleEngine</Text>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Settings')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            
            <FormControl>
              <FormLabel>{t("Core's API URI")}</FormLabel>
              <Input
                type="text"
                value={api}
                onChange={(e) => set_api(e.target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>{t("Debug socket's URI")}</FormLabel>
              <Input
                type="text"
                value={ws}
                onChange={(e) => set_ws(e.target.value)}
              />
            </FormControl>
            <FormControl mt={6} display={"flex"}>
              <FormLabel>{t('Block disabled elements')}</FormLabel>
              <Switch
                isChecked={blDisabled}
                onChange={(e) => {
                  setBlDisabled(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl mt={2} display={"flex"} flexDirection={'column'}>
              <FormLabel>{t('Language')}</FormLabel>
              <Select
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value);
                }}
              >
                <option value={'ru'}>{t('Russian')}</option>
                <option value={'en'}>{t('English')}</option>
              </Select>
            </FormControl>
            <Flex mt={2} mb={2}>
              <Spacer />
              <Button colorScheme="blue" onClick={onSave} mr={3}>
                {t('Save')}
              </Button>
              <Spacer />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
export default SidebarHeader;
