import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  useReactFlow,
  ControlButton,
  Background,
  BackgroundVariant,
  XYPosition,
} from "react-flow-renderer";
import { Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";

import I2_O1 from "./nodes/I2_O1";
import Mercury from "./nodes/Mercury";
import ModbusTCP from "./nodes/Modbus/ModbusTCP";
import Schedule from "./nodes/Schedule";
import MQTT from "./nodes/MQTT/MQTTOutput";
import Telegram from "./nodes/Telegram";
import WS from "./nodes/WS";
import Debug from "./nodes/Debug";
import HTTPInput from "./nodes/HTTP/HTTPInput";
import Note from "./nodes/Note";
import FW from "./nodes/FW";
import BACnetRead from "./nodes/BACnet/BACRP";
import BACnetWrite from "./nodes/BACnet/BACWP";
import BACnetWhoIs from "./nodes/BACnet/BACWI";
import DatatypeFilter from "./nodes/DatatypeFilter";
import PrepairToScada from "./nodes/PrepairToScada"
import CreateAlarm from "./nodes/plugs/actions/CreateAlarm";
import Rubicon from "./nodes/Rubicon";
import SNMPinput from "./nodes/SNMPinput";
import ModbusRTU from "./nodes/Modbus/ModbusRTU";
import MQTTInput from "./nodes/MQTT/MQTTInput";
import RPCrequest from "./nodes/RPCrequest";
import Alarm from "./nodes/Alarm";


//Заглушки
import RPCreply from "./nodes/plugs/actions/RPCreply";
import SaveAttributes from "./nodes/plugs/actions/SaveAttributes";
import SaveTimeSeries from "./nodes/plugs/actions/SaveTimeSeries";
import CalculateDelta from "./nodes/plugs/saturation/CalculateDelta";
import CustomerAttributes from "./nodes/plugs/saturation/CustomerAttributes";
import DeviceAttributes from "./nodes/plugs/saturation/DeviceAttributes";
import OriginatorAttributes from "./nodes/plugs/saturation/OriginatorAttributes";
import RelatedAttributes from "./nodes/plugs/saturation/RelatedAttributes";
import CheckRelation from "./nodes/plugs/filters/CheckRelation";
import FiledExist from "./nodes/plugs/filters/FieldExist";
import MessadgeType from "./nodes/plugs/filters/MessadgeType";
import MessadgeTypeSwitch from "./nodes/plugs/filters/MessadgeTypeSwitch";
import OriginatorType from "./nodes/plugs/filters/OriginatorType";
import OroginatorTypeSwitch from "./nodes/plugs/filters/OriginatorTypeSwitch";
import ScriptFilter from "./nodes/plugs/filters/ScriptFilter";
import Kafka from "./nodes/plugs/publish/Kafka";
import ChangeOriginator from "./nodes/plugs/transform/ChangeOriginator";
import ToEmail from "./nodes/plugs/transform/ToEmail";
import TransformationScript from "./nodes/plugs/transform/TransformationScript";
import AssignToCustomer from "./nodes/plugs/actions/AssignToCustomer";
import ClearAlarm from "./nodes/plugs/actions/ClearAlarm";
import CreateRelation from "./nodes/plugs/actions/CreateRelation";
import Delay from "./nodes/plugs/actions/Delay";
import Generator from "./nodes/plugs/actions/Generator";
import GeofencingEvent from "./nodes/plugs/actions/GeofencingEvent";
import Log from "./nodes/plugs/actions/Log";
import PushToCloud from "./nodes/plugs/actions/PushToCloud";
import PushToEdge from "./nodes/plugs/actions/PushToEdge";
import RemoveRelation from "./nodes/plugs/actions/RemoveRelation";
import SaveToDB from "./nodes/plugs/actions/SaveToDB";
import UnassignFromCustomer from "./nodes/plugs/actions/UnassignFromCustomer";
import RESToutput from "./nodes/HTTP/RESToutput";
import KNXIP from "./nodes/KNXIP";
import TestAlarm from "./nodes/TestAlarm";
import ConsoleLoger from "./nodes/ConsoleLoger";


import "./styles/node.css";
import { HamburgerIcon, InfoIcon } from "@chakra-ui/icons";
import LeftSidebar from "./components/LeftSidebar";
import Guide from "./components/Guide";
import useStore from "./store/store";
import BACnetReadMultiply from "./nodes/BACnet/BACRPM";
import BACnetCOV from "./nodes/BACnet/BACCOV";
import useServer from "./utils/useServer";


const edgeOptions = {
  // animated: true,
  style: {
    // stroke: "white",
    strokeWidth: 3,
    cursor: "pointer",
  },
};

const nodeTypes = {
  i2_o1: I2_O1,
  mercury: Mercury,
  modbusTCP: ModbusTCP,
  shedule: Schedule,
  mqtt: MQTT,
  telegram: Telegram,
  ws: WS,
  debug: Debug,
  http_in: HTTPInput,
  bacnetread : BACnetRead,
  bacnetreadmultiply: BACnetReadMultiply,
  bacnetwrite : BACnetWrite,
  bacnetwhois : BACnetWhoIs,
  bacnetcov : BACnetCOV,
  note : Note,
  FW,
  CreateAlarm,
  KNXIP,
  RESToutput,
  TestAlarm,
  ConsoleLoger,
  DatatypeFilter,
  PrepairToScada,
  Rubicon,
  SNMPinput,
  ModbusRTU,
  MQTTInput,
  RPCrequest,
  Alarm,


  //Заглушки
  RPCreply,
  SaveAttributes,
  SaveTimeSeries,
  CalculateDelta,
  CustomerAttributes,
  DeviceAttributes,
  OriginatorAttributes,
  RelatedAttributes,
  CheckRelation,
  FiledExist,
  MessadgeType,
  MessadgeTypeSwitch,
  OriginatorType,
  OroginatorTypeSwitch,
  ScriptFilter,
  Kafka,
  ChangeOriginator,
  ToEmail,
  TransformationScript,
  AssignToCustomer,
  ClearAlarm,
  
  CreateRelation,
  Delay,
  Generator,
  GeofencingEvent,
  Log,
  PushToCloud,
  PushToEdge,
  RemoveRelation,
  SaveToDB,
  UnassignFromCustomer
};

let debugSocket = new WebSocket("ws://" + window.location.hostname + ":7777");

const App = () => {
  const { makeFlowNode } = useServer();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();

  const { isOpen, onOpen, onClose } = useDisclosure({defaultIsOpen: true});
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const setJson = useStore((state) => state.setCurrentChain)
  const currentChain = useStore((state) => state.currentChain)
  const bg = useColorModeValue("#FAFAFA", "#303030"); // фон места для узлов
  const color = useColorModeValue("#357A9F", "#a4d8f5"); // цвет линий

  useEffect(() => {
    setNodes([]);
    setEdges([]);
  }, []);

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
      const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
      const type = event.dataTransfer.getData('text/plain')
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      addNode(type, position);
    };

  const onNodesChange = useCallback(
    (changes: any) => {
      if (changes[0].type !== "position") {
        setJson(reactFlowInstance.toObject());
        console.log(reactFlowInstance.toObject())
      }
    },
    [reactFlowInstance, setJson]
  );
  const onEdgesChange = useCallback(
    (changes: any) => setJson(reactFlowInstance.toObject()),
    [reactFlowInstance, setJson]
  );
  const onConnect = useCallback(
    (changes: any) => setJson(reactFlowInstance.toObject()),
    [reactFlowInstance, setJson]
  );

  function setDebugInfo(_id: number, _info: any) {
    let new_nds = reactFlowInstance.getNodes().map((nd) => {
      if (String(_id) === nd.id || String(_id) === nd.data.id || _id === nd.data.id) {
        nd.data = {
          ...nd.data,
          json: _info
        }
      }
      return nd;
    });
    reactFlowInstance.setNodes(new_nds);
    setJson(reactFlowInstance.toObject())
  }

  useStore.subscribe((state: any, prevstate: any) => {
    if(state.WS !== prevstate.WS)
    {
      try {
        debugSocket = new WebSocket(state.WS);
        console.log("WebSocket reconnect to " + state.WS)
      } catch (error) {
        console.error("Ошибка при переподключении WS: " + error)
      }
    }
  })

  debugSocket.onmessage = function (event) {
    let data_object = JSON.parse(event.data);
    if (data_object.id === null || data_object.msg === null || data_object.name !== currentChain.name) {
      return;
    } else {
      console.log(event.data);
      setDebugInfo(data_object.id, data_object.msg);
    }
  };

  function addNode(type: string, position: XYPosition) {
    const newNode = makeFlowNode(type, position);
    if (newNode !== undefined) {
      reactFlowInstance.addNodes(newNode);
      setJson(reactFlowInstance.toObject());
    }
  }

  return (
    <Box w="100vw" h="100vh" ref={reactFlowWrapper}>
      <Guide isOpen={isModalOpen} onClose ={onModalClose}/>
      <LeftSidebar
        isOpen={isOpen} // для того чтобы по дефолту был открыт
        onClose={onClose}
        onModalOpen={onModalOpen}
      />
      <ReactFlow
        defaultNodes={nodes}
        defaultEdges={edges}
        defaultEdgeOptions={edgeOptions}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDragOver={onDragOver}
        onDrop={onDrop}
        nodeTypes={nodeTypes}
        fitView
        attributionPosition="bottom-left"
      >
        <Background
          variant={BackgroundVariant.Dots}
          color={color}
          style={{ backgroundColor: bg }}
          gap={25}
        />
        <MiniMap style={{ backgroundColor: bg }} />
        <Controls
          style={{
            bottom: "auto",
            left: "auto",
            top: "20px",
            right: "15px",
          }}
        >
          <ControlButton onClick={onOpen}>
            <HamburgerIcon color="black" />
          </ControlButton>
          <ControlButton  onClick={onModalOpen}>
            <InfoIcon color="black" />
          </ControlButton>
        </Controls>
      </ReactFlow>
    </Box>
  );
};

export default App;
