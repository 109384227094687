import React, {useEffect, useState} from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import OutputNode from "./types/OutputNode";
import { useTranslation } from "react-i18next";

function FW({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [uri, setUri] = useState(
      data.settings !== undefined && typeof data.settings !== "string" && data.settings.hasOwnProperty('uri')
          ? data.settings.uri
          : "https://fw.4iot.pro/api/"
  );
  const [owner_id, setOwner] = useState(
      data.settings !== undefined && typeof data.settings !== "string" && data.settings.hasOwnProperty('owner_id')
          ? data.settings.owner_id
          : "fire_events"
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          uri,
          owner_id
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const {t} = useTranslation();
  const label = t('fw.name')

  return (
    <OutputNode selected={selected} onOpen={onOpen} label={label}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('fw.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('fw.props.0')}</FormLabel>
              <Input
                type="text"
                value={uri}
                onChange={(e) => setUri(e.target.value)}
              />
              <FormLabel>{t('fw.props.1')}</FormLabel>
              <Input
                type="text"
                value={owner_id}
                onChange={(e) => setOwner(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </OutputNode>
  );
}

export default FW;
