import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import DebugNode from "./types/DebugNode";
import { useTranslation } from "react-i18next";

function Note({ selected, id }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const label = t('debug.note.name')
  const plh = t('debug.note.plh')
  const [name, setName] = useState(`${label}  ${id}`);
  const [comment, setComment] = useState("");
  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          note: comment,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  return (
    <DebugNode selected={selected} label={name} onOpen={onOpen} childPlace="b">
      <Textarea
        placeholder={plh}
        value={comment}
        name={"text"}
        id={"text"}
        className={"nodrag"}
        onChange={(e) => { setComment(e.target.value) }}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormLabel>{t('debug.note.props.0')}</FormLabel>
            <FormControl>
              <Input value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormLabel>{t('debug.note.props.1')}</FormLabel>
            <FormControl>
              <Textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </DebugNode>

  );
}

export default Note;
