import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { BsBellFill } from "react-icons/bs";
import useStore, { NodeData } from "../../../store/store";
import { useTranslation } from "react-i18next";
import DebugNode from "../../types/DebugNode";

function CreateAlarm({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [register_name, setRName] = useState(
    data.settings !== undefined ? data.settings.register_name : "flame-2"
  );
  const [register_description, setRDesc] = useState(
    data.settings !== undefined ? data.settings.register_description : "Тревога датчик 2"
  );
  const [data_type, setDatatype] = useState(
    data.settings !== undefined ? data.settings.data_type : "Boolean"
  );
  const [min_value, setminValue] = useState(
    data.settings !== undefined ? data.settings.min_value : 0
  );
  const [max_value, setmaxValue] = useState(
    data.settings !== undefined ? data.settings.max_value : 0
  );
  const [value, setValue] = useState(
    data.settings !== undefined ? data.settings.value : "0"
  );
  const [threshold, setThreshold] = useState(
    data.settings !== undefined ? data.settings.value : 0
  );
  const [booleanHidden, setBooleanHidden] = useState(
    data.settings !== undefined ? data.settings.booleanHidden : false
  );
  const [minmaxHidden, setMinMaxHidden] = useState(
    data.settings !== undefined ? data.settings.minmaxHidden : true
  );
  const [thresholdHidden, setThresholdHidden] = useState(
    data.settings !== undefined ? data.settings.thresholdHidden : true
  );

  //const [data_type, setDatatype] = useState("INT16");

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          register_name,
          register_description,
          data_type,
          min_value,
          max_value,
          value: parseInt(value),
          threshold,
          booleanHidden,
          minmaxHidden,
          thresholdHidden,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    console.log(data);
    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation();

  const label = t('alarm.create.name')

  return (
    <DebugNode label={label} onOpen={onOpen} selected={selected} icon={ <BsBellFill />}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('alarm.create.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('alarm.create.props.0')}</FormLabel>
              <Input
                type="text"
                value={register_name}
                onChange={(e) => setRName(e.target.value)}
              />
              <FormLabel>{t('alarm.create.props.1')}</FormLabel>
              <Input
                type="text"
                value={register_description}
                onChange={(e) => setRDesc(e.target.value)}
              />
              <FormLabel>{t('alarm.create.props.2')}</FormLabel>
              <Select
                name="type"
                // placeholder="Выберите тип"
                defaultValue={"Boolean"}
                value={data_type}
                onChange={(e) => {
                  setDatatype(e.target.value);
                  switch (e.target.value) {
                    case "Boolean":
                      setBooleanHidden(false);
                      setMinMaxHidden(true);
                      setThresholdHidden(true);
                      break;
                    case "MinMaxValue":
                      setBooleanHidden(true);
                      setMinMaxHidden(false);
                      setThresholdHidden(true);
                      break;
                    case "Threshold":
                      setBooleanHidden(true);
                      setMinMaxHidden(true);
                      setThresholdHidden(false);
                      break;
                  }
                }}
              >
                <option value="Boolean">{t('Boolean')}</option>
                <option value="MinMaxValue">{t('MinMaxValue')}</option>
                <option value="Threshold">{t('Threshold')}</option>
              </Select>
              <FormLabel> </FormLabel>

              <RadioGroup
                defaultValue={value}
                hidden={booleanHidden}
                onChange={(e) => {
                  setValue(e);
                }}
              >
                <Stack spacing={4} direction="row">
                  <Radio value="1">{t('True')}</Radio>
                  <Radio value="0">{t('False')}</Radio>
                </Stack>
              </RadioGroup>

              <FormLabel hidden={thresholdHidden}>
                {t('Threshold')} {t('value')} (x {"=="} {t('value')})
              </FormLabel>
              <Input
                type="number"
                value={threshold}
                onChange={(e) => setThreshold(parseFloat(e.target.value))}
                hidden={thresholdHidden}
              />

              <FormLabel hidden={minmaxHidden}>{t('Min')}</FormLabel>
              <Input
                type="number"
                value={min_value}
                onChange={(e) => setminValue(parseFloat(e.target.value))}
                hidden={minmaxHidden}
              />
              <FormLabel hidden={minmaxHidden}>{t('Max')}</FormLabel>
              <Input
                type="number"
                value={max_value}
                onChange={(e) => setmaxValue(parseFloat(e.target.value))}
                hidden={minmaxHidden}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DebugNode>
  );
}

export default CreateAlarm;
