import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import { MdOutlineChangeCircle } from "react-icons/md";

function ChangeOriginator({
  data,
  selected,
}: {
  data: any;
  selected: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [source, setSource] = React.useState("Source originator");
  const [direction, setDirection] = React.useState("From");
  const [new_originator, setNew] = React.useState("New originator");

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Change originator</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<MdOutlineChangeCircle />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change originator settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Originator source</FormLabel>
              <Input
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Direction</FormLabel>
              <Input
                type="text"
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>New originator</FormLabel>
              <Input
                type="text"
                value={new_originator}
                onChange={(e) => setNew(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default ChangeOriginator;
