import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const guide = definePartsStyle({
  header: {
    textAlign: "center",
  },
  dialog: {
    maxW: "60em",
    maxH: "95%"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: { guide },
});
