import React, { useEffect, useState } from "react";
import { Handle, Node, NodeProps, Position, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import useStore, { NodeData } from "../../store/store";
import { useTranslation } from "react-i18next";
import OutputNode from "../types/OutputNode";

function BACnetWrite({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [device_name, setName] = useState("");
  const [device_instance, setInstance] = useState(260001);
  const [object_type, setType] = useState(0);
  const [object_instance, setObjectInstance] = useState(0);
  const [property_name, setPropName] = useState("");
  const [property, setProperty] = useState(85);
  const [priority, setPriority] = useState(16);
  const [index, setIndex] = useState(-1);
  //const [index, setFrequency] = useState((data.settings !== undefined && typeof(data.settings) !== 'string') ?  data.settings.frequency : 1000);
  const [tag, setTag] = useState(4);
  const [bacvalue, setBacValue] = useState(0);
  const [file, setFile] = useState<File | null | undefined>(undefined);
  const [current_tab, setTab] = useState(1);
  const [command] = useState("wp");

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const onSave = () => {
    switch (current_tab) {
      case 1:
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
          if (node.id === id) {
            node.data.settings = {
              device_name,
              device_instance,
              object_type,
              object_instance,
              property_name,
              property,
              priority,
              index,
              tag,
              bacvalue,
              command,
            };
          }
          return node
        })
        )
        setJson(inst.toObject())
        break;

      case 2:
        let reader = new FileReader();
        reader.onload = () => {
          inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
            if (node.id === id) {
              node.data.settings = reader.result!.toString()
            }
            return node
          })
          )
          setJson(inst.toObject())
        };
        reader.readAsText(file!);
        break;
    }
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t('bacnet.write.name')

  return (
    <OutputNode selected={selected} label={label} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('bacnet.write.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Tabs>
              <TabList>
                <Tab
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  {t('bacnet.write.tabs.0')}
                </Tab>
                <Tab
                  onClick={() => {
                    setTab(2);
                  }}
                >
                  {t('bacnet.write.tabs.1')}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t('bacnet.write.props.0.0')}</FormLabel>
                    <Input
                      placeholder=""
                      type="text"
                      value={device_name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <FormLabel>{t('bacnet.write.props.0.1')}</FormLabel>
                    <Input
                      type="number"
                      value={device_instance}
                      onChange={(e) => setInstance(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.2')}</FormLabel>
                    <Input
                      type="number"
                      value={object_type}
                      onChange={(e) => setType(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.3')}</FormLabel>
                    <Input
                      type="number"
                      value={object_instance}
                      onChange={(e) =>
                        setObjectInstance(parseInt(e.target.value))
                      }
                    />
                    <FormLabel>{t('bacnet.write.props.0.4')}</FormLabel>
                    <Input
                      type="text"
                      value={property_name}
                      onChange={(e) => setPropName(e.target.value)}
                    />
                    <FormLabel>{t('bacnet.write.props.0.5')}</FormLabel>
                    <Input
                      type="number"
                      value={property}
                      onChange={(e) => setProperty(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.6')}</FormLabel>
                    <Input
                      type="number"
                      value={priority}
                      onChange={(e) => setPriority(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.7')}</FormLabel>
                    <Input
                      type="number"
                      value={index}
                      onChange={(e) => setIndex(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.8')}</FormLabel>
                    <Input
                      type="number"
                      value={tag}
                      onChange={(e) => setTag(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('bacnet.write.props.0.9')}</FormLabel>
                    <Input
                      type="number"
                      value={bacvalue}
                      onChange={(e) => setBacValue(parseInt(e.target.value))}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t('bacnet.write.props.1.0')}</FormLabel>
                    <Input
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setFile(e.target.files?.item(0));
                      }}
                    />
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </OutputNode>
  );
}

export default BACnetWrite;
