import React from "react";
import { NodeProps } from "react-flow-renderer";
import { NodeData } from "../store/store";
import DebugNode from "./types/DebugNode";
import { useTranslation } from "react-i18next";

function PrepairToScada({ selected, id, data }: NodeProps<NodeData>) {

  const {t} = useTranslation();
  const label = t('scada.name')
  return (
    <DebugNode label={label} selected={selected} />
  );
}

export default PrepairToScada;
