import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import OutputNode from "./types/OutputNode";
import { useTranslation } from "react-i18next";

function Telegram({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState(true);

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [tg_user_id, setUser] = useState(
    data.settings !== undefined ? data.settings.tg_user_id : "user"
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          tg_user_id,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  const onSettings = () => {
    onOpen();
    setUser(data.settings.tg_user_id);
  }

  useEffect(() => {
    onSave();
  }, []);

  const { t }= useTranslation();

  const label = t('tg.name')

  return (
    <OutputNode onOpen={onSettings} selected={selected} label={label}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('tg.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('tg.props.0')}</FormLabel>
              <Input
                type="text"
                onChange={(e) => setUser(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </OutputNode>
  );
}

export default Telegram;
