import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import App from './App';
import { ReactFlowProvider } from 'react-flow-renderer';
import theme from './styles/apllicationTheme';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './lang';


i18next.use(initReactI18next).init({
  resources: resources,
  lng: "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false
  }
})

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
root.render(
  <ChakraProvider theme={theme}>
    <ReactFlowProvider>
      <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      </React.StrictMode>
    </ReactFlowProvider>
  </ChakraProvider>,
);


