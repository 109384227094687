import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import InputNode from "./types/InputNode";
import { useTranslation } from "react-i18next";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import useStore, { NodeData } from "../store/store";

function Rubicon({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [addr, setAddr] = useState(
    data.settings !== undefined ? data.settings.addr : "10.186.0.113"
  );
  const [port, setPort] = useState(
    data.settings !== undefined ? data.settings.port : 7654
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          addr,
          port
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);
  const { t } = useTranslation();
  const label = t('rubicon.name')

  return (
    <InputNode selected={selected} onOpen={onOpen} label={label}>
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('rubicon.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('rubicon.props.0')}</FormLabel>
              <Input
                type="text"
                value={addr}
                onChange={(e) => setAddr(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('rubicon.props.1')}</FormLabel>
              <Input
                type="number"
                value={port}
                onChange={(e) => setPort(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}

export default Rubicon;
