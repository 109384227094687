import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Lamp from "../components/Lamp";
import InputNode from "./types/InputNode";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import useStore, { NodeData } from "../store/store";
import { useTranslation } from "react-i18next";
import DebugNode from "./types/DebugNode";
import {BsBellFill} from "react-icons/bs";

function Alarm({ selected, id, data }: NodeProps<NodeData>) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const inst = useReactFlow()
    const setJson = useStore((state) => state.setCurrentChain)

    const [settings, setSettings] = useState<string>(
        data.settings && Object.keys(data.settings).length > 0 && typeof data.settings == "string"
            ? data.settings
            : ""
    );
    const [csv, setCSV] = useState<string[][]>([]);

    const onSave = () => {
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
                if (node.id === id) {
                    node.data.settings = settings
                }
                return node
            })
        )
        setJson(inst.toObject())
        onClose();
    }

    useEffect(() => {
        onSave();
    }, []);

    const { t } = useTranslation()

    const label = t('alarm.alarm.name')

    return (
        <DebugNode selected={selected} label={label} onOpen={onOpen} icon={ <BsBellFill />}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('alarm.alarm.head')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>{t("alarm.alarm.props.0")}</FormLabel>
                            <Input
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                    let reader = new FileReader();
                                    reader.onload = () => {
                                        const tmp = reader.result!.toString();
                                        setSettings(tmp);
                                        const rows = tmp.split("\n");
                                        let elements: string[][] = [];
                                        rows.forEach((row) => {
                                            const columns = row.split(";");
                                            elements.push(columns);
                                        });
                                        setCSV(elements);
                                    };
                                    reader.readAsText(e.target.files?.item(0)!);
                                }}
                            />
                        </FormControl>
                        {settings}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={onSave} mr={3}>
                            {t("Save")}
                        </Button>
                        <Button onClick={onClose}>{t("Cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </DebugNode>
    );
}

export default Alarm;
