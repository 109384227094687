import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Lamp from "../../components/Lamp";
import useStore, { NodeData } from "../../store/store";
import { useTranslation } from "react-i18next";
import InputNode from "../types/InputNode";

function ModbusRTU({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [settings, setSettings] = useState<string>(
    data.settings !== undefined && typeof data.settings == "string"
      ? data.settings
      : ""
  );
  const [csv, setCSV] = useState<string[][]>([]);

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = settings
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t('modbus.rtu.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
      <Lamp isWork={data.json ? data.json.status === 'Ok' : false} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('modbus.rtu.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('modbus.rtu.props.0')}</FormLabel>
              <Input
                  type="file"
                  accept=".csv"
                  // onChange={(e) => {setFile(e.target.files?.item(0))}}
                  onChange={(e) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                      const tmp = reader.result!.toString();
                      setSettings(tmp);
                      const rows = tmp.split("\n");
                      let elements: string[][] = [];
                      rows.forEach((row) => {
                        const columns = row.split(";");
                        elements.push(columns);
                      });
                      setCSV(elements);
                    };
                    reader.readAsText(e.target.files?.item(0)!);
                  }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}

export default ModbusRTU;
