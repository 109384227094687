import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import { useTranslation } from "react-i18next";
import WorkerNode from "./types/WorkerNode";

function DatatypeFilter({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [filter, setFilter] = useState("ALOW");
  const [type, setType] = useState("null");
  const [selected_types, setSelected] = useState<string[]>([]);

  const onAdd = () => {
    if (!selected_types.includes(type)) {
      let tmp = selected_types;
      tmp.push(type);
      setSelected(tmp);
      console.log(selected_types);
    }
  };

  const onRemove = () => {
    if (selected_types.includes(type)) {
      let tmp = selected_types;
      tmp.splice(
        selected_types.findIndex((item) => item === type),
        1
      );
      setSelected(tmp);
      console.log(selected_types);
    }
  };

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          types: selected_types,
          filter,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  const { t }= useTranslation();
  const label = t('filters.datatype.name')

  return (
    <WorkerNode label={label} selected={selected} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('filters.datatype.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('filters.datatype.props.0')}</FormLabel>
              <Select
                name="type"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="ALLOW">{t('Allow')}</option>
                <option value="REJECT">{t('Reject')}</option>
              </Select>
              <FormLabel>{t('filters.datatype.props.1')}</FormLabel>
              <HStack>
                <Select
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="null">null</option>
                  <option value="number">number</option>
                  <option value="integer">integer</option>
                  <option value="double">double</option>
                  <option value="boolean">boolean</option>
                  <option value="string">string</option>
                  <option value="array">array</option>
                  <option value="object">object</option>
                </Select>
                <Stack>
                  <Button colorScheme="blue" onClick={onAdd} mr={3}>
                    {t('Add')}
                  </Button>
                  <Button colorScheme="red" onClick={onRemove} mr={3}>
                    {t('Remove')}
                  </Button>
                </Stack>
              </HStack>
            </FormControl>
            <FormLabel>{t('filters.datatype.props.2')}</FormLabel>
            <Input type="text" value={selected_types} contentEditable={false} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </WorkerNode>
  );
}

export default DatatypeFilter;
