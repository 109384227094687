import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Block: ComponentStyleConfig = {
  baseStyle: {
    background: "white",
    textAlign: "center",
    padding: "5px",
    border: "1px solid rgb(0, 0, 0)",
    borderRadius: "5px",
    height: "fit-content",
  },
  variants: {
    debug: props => ({
      bg: mode('#f19393', '#9d3030')(props),
    }),
    input: props => ({
      bg: mode('#a4d8f5', '#357A9F')(props),
    }),
    output: (props) => ({
      bg: mode('#71cf9c', '#258752')(props),
    }),
    worker: (props) => ({
      bg: mode('#e7d2a7', '#967736')(props),
    }),
    disabled: props => ({
      bg: mode('gray.200', 'gray.800')(props),
    }),
  },
};

// @TODO убрать в какое-нибудь другое место
export const darkOceanEdited = {
  base00: "#2d3748", // цвет заднего фона
  base01: "#343D46",
  base02: "#4F5B66",
  base03: "#65737E",
  base04: "#A7ADBA",
  base05: "#C0C5CE",
  base06: "#CDD3DE",
  base07: "#D8DEE9",
  base08: "#EC5f67",
  base09: "#F99157",
  base0A: "#FAC863",
  base0B: "#99C794",
  base0C: "#5FB3B3",
  base0D: "#6699CC",
  base0E: "#C594C5",
  base0F: "#AB7967",
};

export const invertedOceanEdited = {
  base00: "#FFFFFF", // цвет заднего фона
  base01: "#cbc2b9",
  base02: "#b0a499",
  base03: "#9a8c81",
  base04: "#585245",
  base05: "#3f3a31",
  base06: "#322c21",
  base07: "#272116",
  base08: "#13a098",
  base09: "#066ea8",
  base0A: "#05379c",
  base0B: "#66386b",
  base0C: "#a04c4c",
  base0D: "#996633",
  base0E: "#3a6b3a",
  base0F: "#548698",
};
