import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import InputNode from "./types/InputNode";
import { useTranslation } from "react-i18next";



export default function KNXIP({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [ip_addr, setIPaddr] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.ip_addr
      : "10.188.20.40"
  );
  const [port, setPort] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.port
      : 3671
  );
  const [device_name, setDName] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.device_name
      : ""
  );
  const [group_address, setGroupAddress] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.group_address
      : "0/0/0"
  )
  const [name, setName] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.name
      : ""
  );
  const [white_list, setWhiteList] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.white_list
      : ""
  )
  const [black_list, setBlackList] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.black_list
      : ""
  )

  const [data_type, setDatatype] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.data_type
      : "bool"
  );

  const [scale, setScale] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.scale
      : 1
  );

  const [frequency, setFrequency] = useState(
    data.settings !== undefined && typeof data.settings !== "string"
      ? data.settings.frequency
      : 1000
  );

  const [settings, setSettings] = useState<string>(
    data.settings !== undefined && typeof data.settings == "string"
      ? data.settings
      : ""
  );
  const [current_tab, setTab] = useState(1);
  // нужно чтобы потом выгружать настройки в csv-формате
  const [csv, setCSV] = useState<string[][]>([]);

  const onSave = () => {
    if (device_name === "" && current_tab === 1)
      //Это нужно т.к. при рерндере срабатывает этот мето. И если у нас были настройки из файла, то они теряются
      return;
    switch (current_tab) {
      case 1:
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
          if (node.id === id) {
            node.data.settings = {
              device_name,
              ip_addr,
              port,
              group_address,
              name,
              white_list,
              black_list,
              data_type,
              scale,
              frequency,
            }
          }
          return node
        })
        )
        setJson(inst.toObject())
        break;

      case 2:
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
          if (node.id === id) {
            node.data.settings = settings
          }
          return node
        })
        )
        setJson(inst.toObject())
        break;
    }
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t('knx.ip.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('knx.ip.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Tabs>
              <TabList>
                <Tab
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  {t('knx.ip.tabs.0')}
                </Tab>
                <Tab
                  onClick={() => {
                    setTab(2);
                  }}
                >
                  {t('knx.ip.tabs.1')}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t('knx.ip.props.0')}</FormLabel>
                    <Input
                      type="text"
                      placeholder={(() => {const label = t('knx.ip.props.0'); return label})()}
                      value={device_name}
                      onChange={(e) => setDName(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.1')}</FormLabel>
                    <Input
                      type="text"
                      value={ip_addr}
                      onChange={(e) => setIPaddr(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.2')}</FormLabel>
                    <Input
                      type="number"
                      value={port}
                      onChange={(e) => setPort(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('knx.ip.props.3')}</FormLabel>
                    <Input
                      type="text"
                      value={group_address}
                      onChange={(e) => setGroupAddress(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.4')}</FormLabel>
                    <Input
                      type="text"
                      placeholder={(() => {const label = t('knx.ip.props.4'); return label})()}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.5')}</FormLabel>
                    <Input
                      type="text"
                      value={white_list}
                      onChange={(e) => setWhiteList(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.6')}</FormLabel>
                    <Input
                      type="text"
                      value={black_list}
                      onChange={(e) => setBlackList(e.target.value)}
                    />
                    <FormLabel>{t('knx.ip.props.7')}</FormLabel>
                    <Select
                      name="type"
                      value={data_type}
                      onChange={(e) => setDatatype(e.target.value)}
                    >
                      <option value="bool">bool</option>
                      <option value="2bits">2bits</option>
                      <option value="uint8">uint8</option>
                      <option value="int8">int8</option>
                      <option value="uint16">uint16</option>
                      <option value="int16">int16</option>
                      <option value="float16">float16</option>
                      <option value="uint32">uint32</option>
                      <option value="int32">int32</option>
                      <option value="float32">float32</option>
                    </Select>
                    <FormLabel>{t('knx.ip.props.8')}</FormLabel>
                    <Input
                      type="number"
                      value={scale}
                      onChange={(e) => setScale(parseInt(e.target.value))}
                    />
                    <FormLabel>{t('knx.ip.props.9')}</FormLabel>
                    <Input
                      type="number"
                      value={frequency}
                      onChange={(e) => setFrequency(parseInt(e.target.value))}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t('knx.ip.props.10')}</FormLabel>
                    <Input
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.onload = () => {
                          const tmp = reader.result!.toString();
                          setSettings(tmp);
                          const rows = tmp.split("\n");
                          let elements: string[][] = [];
                          rows.forEach((row) => {
                            const columns = row.split(";");
                            elements.push(columns);
                          });
                          setCSV(elements);
                        };
                        reader.readAsText(e.target.files?.item(0)!);
                      }}
                    />
                  </FormControl>
                  {settings}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t("Cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}
