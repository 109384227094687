import React from "react";
import { useTranslation } from "react-i18next";
import InputNode from "../types/InputNode";
import { NodeProps } from "react-flow-renderer";
import { NodeData } from "../../store/store";

function BACnetWhoIs({ selected }: NodeProps<NodeData>) {

  const {t} = useTranslation();

  const label = t('bacnet.wi.name')
  return (
    <InputNode selected={selected} label={label} />
  );
}

export default BACnetWhoIs;
