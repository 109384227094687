import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import { SiApachekafka } from "react-icons/si";

function Kafka({ data, selected }: { data: any; selected: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [topic, setTopic] = React.useState("my-topic");
  const [uri, setUri] = React.useState("localhost:9092");
  const [retry, setRetry] = React.useState(0);
  const [size, setSize] = React.useState(16384);
  const [buf_time, setBuf] = React.useState(0);
  const [max_size_buf, setMaxSize] = React.useState(33554432);
  const [acknowledgment, setAck] = React.useState(-1);

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="output" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Kafka</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<SiApachekafka />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Kafka settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Device type</FormLabel>
              <Input
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Bootstrap servers</FormLabel>
              <Input
                type="text"
                value={uri}
                onChange={(e) => setUri(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Automatically retry times if fails</FormLabel>
              <Input
                type="number"
                value={retry}
                onChange={(e) => setRetry(parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Time to buffer locally (ms)</FormLabel>
              <Input
                type="number"
                value={buf_time}
                onChange={(e) => setBuf(parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Produces batch size in bytes</FormLabel>
              <Input
                type="number"
                value={size}
                onChange={(e) => setSize(parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Client buffer max size</FormLabel>
              <Input
                type="number"
                value={max_size_buf}
                onChange={(e) => setMaxSize(parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Number of acknowledgments</FormLabel>
              <Input
                type="number"
                value={acknowledgment}
                onChange={(e) => setAck(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default Kafka;
