import { ReactFlowJsonObject } from "react-flow-renderer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface NodeData {
  settings: any
  json?: any
}

export type Chain = ReactFlowJsonObject<NodeData, any>;

// const API = 'http://172.16.16.5:5555/api'

interface AppState {
  lastId: string;
  API: string;
  WS: string;
  blockDisabled: boolean;
  chainList: (Chain & {name: string})[],
  currentChain: {
    name: string;
  } & Chain;
  shouldBlockDisabled: (value: boolean) => void;
  setAPI: (value: string) => void;
  setWS: (value: string) => void;
  setChainList: (value: (Chain & {name: string})[]) => void;
  setCurrentChain: (value: Chain) => void;
  setLastId: (value: string) => void;
}

const useStore = create<AppState, [
  ['zustand/devtools', never]
]
>(
  devtools(
    (set) => ({
      lastId: "0",
      API: `${window.location.protocol}//${window.location.hostname}:5555/api`,
      WS: (() => {
        const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss';
        return `${wsProtocol}//${window.location.hostname}:7777`;
      })(),
      blockDisabled: true,
      chainList: [],
      currentChain: {
        name: "",
        nodes: [],
        edges: [],
        viewport: {
          x: 0,
          y: 0,
          zoom: 1
        }
      },
      shouldBlockDisabled: (value) =>
        set(() => ({ blockDisabled: value })),
      setAPI: (newValue) => set(() => ({ API: newValue })),
      setWS: (newValue) => set(() => ({ WS: newValue })),
      setChainList: (newValue) => set(() => ({
        chainList: newValue
      })),
      setCurrentChain: (newValue) => set((state) => ({
        currentChain: {
          name: state.currentChain.name,
          ...newValue
        }
      })),
      setLastId: (newVal) => set(() => ({
        lastId: newVal
      }))
    })
  )
  );

export default useStore;
