import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import WorkerNode from "./types/WorkerNode";
import { useTranslation } from "react-i18next";

function Schedule({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()
  

  const [week, setWeek] = useState(0);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(60);

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          week,
          day,
          hour,
          minute,
          second,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation();

  const label = t('filters.schedule.name')

  return (
    <WorkerNode label={label} selected={selected} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('filters.schedule.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('filters.schedule.props.0')}</FormLabel>
              <Input
                type="number"
                value={week}
                onChange={(e) => setWeek(parseInt(e.target.value))}
              />
              <FormLabel>{t('filters.schedule.props.1')}</FormLabel>
              <Input
                type="number"
                value={day}
                onChange={(e) => setDay(parseInt(e.target.value))}
              />
              <FormLabel>{t('filters.schedule.props.2')}</FormLabel>
              <Input
                type="number"
                value={hour}
                onChange={(e) => setHour(parseInt(e.target.value))}
              />
              <FormLabel>{t('filters.schedule.props.3')}</FormLabel>
              <Input
                type="number"
                value={minute}
                onChange={(e) => setMinute(parseInt(e.target.value))}
              />
              <FormLabel>{t('filters.schedule.props.4')}</FormLabel>
              <Input
                type="number"
                value={second}
                onChange={(e) => setSecond(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </WorkerNode>
  );
}

export default Schedule;
