import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import InputNode from "../types/InputNode";
import { useTranslation } from "react-i18next";
import useStore, { NodeData } from "../../store/store";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";

function HTTPInput({ selected, id }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const API = useStore((state) => state.API);
  
  const [addr, setAddr] = useState(API + "/pushdata");
  const [content, setContent] = useState("application/json");
  const [method, setMethod] = useState("POST");

  

  const onSave = () => {
    let addr_list = [];
    addr.split(",").forEach((el) => addr_list.push(parseInt(el)));
    let url = new URL(addr);

    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
        if (node.id === id){
          node.data.settings = {
            host: url.host,
            port: url.port,
            pathname: url.pathname,
            content,
            method,
          }
        }
        return node
      })
    )
    setJson(inst.toObject())
    
    onClose();
  };

  useEffect(() => {
    onSave(); // чтобы сохранить в первый раз
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation()

  const label = t('http.in.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('http.in.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('http.in.props.0')}</FormLabel>
              <Input
                type="text"
                value={addr}
                onChange={(e) => setAddr(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('http.in.props.1')}</FormLabel>
              <Select
                name="type"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              >
                <option value="application/json">JSON</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{t('http.in.props.2')}</FormLabel>
              <Select
                name="type"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="POST">POST</option>
                <option value="GET">GET</option>
                <option value="PATCH">PATCH</option>
                <option value="DEL">DEL</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </InputNode>

  );
}

export default HTTPInput;
