import React from "react";
import Block from "../../components/Block";
import { Handle, Position } from "react-flow-renderer";
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

type Place = 'b' | 't' | 'l' | 'r'

interface DebugNodeProps {
  selected: boolean,
  label: string,
  disabled?: boolean,
  icon?: React.ReactElement,
  childPlace?: Place,
  onOpen?: () => void,
  children?: React.ReactNode
}

export default function DebugNode(props: DebugNodeProps) {
  const { selected = false, disabled = false, icon, childPlace = 'r', onOpen, label, children } = props
  const style = {
    width: '10px',
    height: '10px',
    border: '1px solid rgb(0, 0, 0)',
    background: '#b1b4be',
    cursor: 'pointer',
  }
  const { t } = useTranslation()

  const button = icon ? <IconButton
    aria-label="icon button"
    variant="outline"
    size="sm"
    icon={icon}
    onClick={onOpen}>
    {t('Settings')}
  </IconButton> : <Button
    variant="outline"
    size="sm"
    rightIcon={<VscSettings />}
    onClick={onOpen}
  >
    {t('Settings')}
  </Button>

  let content;

  switch (childPlace) {
    case 'l': {
      <HStack spacing={2} padding={1} minH={10}>
        {children}
        <label htmlFor="text">{label}</label>
        {children && button}
      </HStack>
      break;
    }
    case 'r': {
      content =
        <HStack spacing={2} padding={1} minH={10}>
          <label htmlFor="text">{label}</label>
          {children && button}
          {children}
        </HStack>
      break;
    }
    case 't': {
      content = <VStack>
        {children}
        <HStack spacing={2} padding={1} minH={10}>
          <label htmlFor="text">{label}</label>
          {children && button}
        </HStack>
      </VStack>
      break;
    }
    case 'b': {
      content = <VStack>
        <HStack spacing={2} padding={1} minH={10}>
          <label htmlFor="text">{label}</label>
          {children && button}
        </HStack>
        {children}
      </VStack>
      break;
    }
    default: {
      content =
        <HStack spacing={2} padding={1} minH={10}>
          <label htmlFor="text">{label}</label>
          {children && button}
          {children}
        </HStack>
      break;
    }
  }

  return (
    <Block variant="debug" selected={selected} disabled={disabled}>
      <Handle
        type="source"
        style={style}
        position={Position.Right}
        id="0"
      />
      <Handle
        type="target"
        style={style}
        position={Position.Left}
        id="0"
      /> 
      {content}
    </Block>
  )
}