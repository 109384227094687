// const API = 'http://172.16.16.5:5555/api'
// export const API = 'http://re.4iot.pro:5555/api'

// export let API = 'http://127.0.0.1:5555/api';
// export let debugSocket = new WebSocket("ws://localhost:7777");


export let API = "https://" + window.location.hostname + ":5555/api";
export const debugSocket = new WebSocket("wss://" + window.location.hostname + ":7777");

//export let currentLink = window.location.host;
//Docker config
// if(currentLink !== 'localhost:3000')
// {
//     API = 'http://' + currentLink + ':5555/api';
//     debugSocket = new WebSocket('ws://' + currentLink + ':7777');
// }

//export const debugSocket = new WebSocket("wss://re.4iot.pro:7777");

export const allNodes = [
  {
    name: "Input nodes",
    group: "input",
    entities: [
      {
        name: "http.in.name",
        nodeType: "HTTPInput",
        disabled: false,
      },
      {
        name: "modbus.tcp.name",
        nodeType: "ModbusTCP",
        disabled: false,
      },
      {
        name: "modbus.rtu.name",
        nodeType: "ModbusRTU",
        disabled: false,
      },
      {
        name: "knx.ip.name",
        nodeType: "KNXIP",
        disabled: false,
      },
      {
        name: "bacnet.rp.name",
        nodeType: "BACnetRead",
        disabled: false,
      },
      {
        name: "bacnet.rpm.name",
        nodeType: "BACnetReadMultiply",
        disabled: false,
      },
      {
        name: "bacnet.cov.name",
        nodeType: "BACnetCOV",
        disabled: false,
      },
      {
        name: "bacnet.wi.name",
        nodeType: "BACnetWhoIs",
        disabled: false,
      },
      {
        name: "mercury.name",
        nodeType: "Mercury",
        disabled: false,
      },
      {
        name: "snmp.name",
        nodeType: "SNMPinput",
        disabled: false,
      },
      {
        name: "rubicon.name",
        nodeType: "Rubicon",
        disabled: false,
      },
      {
        name: "mqtt.in.name",
        nodeType: "MQTTInput",
        disabled: false,
      }
    ],
  },
  {
    name: "Filters and routing",
    group: "filter",
    entities: [
      {
        name: "filters.schedule.name",
        nodeType: "Schedule",
        disabled: false,
      },
      {
        name: "filters.datatype.name",
        nodeType: "DatatypeFilter",
        disabled: false,
      },
      {
        name: "Отношение сообщения",
        nodeType: "CheckRelation",
        disabled: true,
      },
      {
        name: "Наличие полей сообщения",
        nodeType: "FiledExist",
        disabled: true,
      },
      {
        name: "Проверка типа сообщения",
        nodeType: "MessadgeType",
        disabled: true,
      },
      {
        name: "Выбор типа сообщения",
        nodeType: "MessadgeTypeSwitch",
        disabled: true,
      },
      {
        name: "Проверка типа отправителя",
        nodeType: "OriginatorType",
        disabled: true,
      },
      {
        name: "Выбор типа отправителя",
        nodeType: "OroginatorTypeSwitch",
        disabled: true,
      },
      {
        name: "Свой фильтр",
        nodeType: "ScriptFilter",
        disabled: true,
      },
    ],
  },
  {
    name: "Adding information",
    group: "comp",
    entities: [
      {
        name: "Вычисление дельты",
        nodeType: "CalculateDelta",
        disabled: true,
      },
      {
        name: "Атрибуты получателя",
        nodeType: "CustomerAttributes",
        disabled: true,
      },
      {
        name: "Атрибуты устройства",
        nodeType: "DeviceAttributes",
        disabled: true,
      },
      {
        name: "Атрибуты отправителя",
        nodeType: "OriginatorAttributes",
        disabled: true,
      },
      {
        name: "Связанные атрибуты",
        nodeType: "RelatedAttributes",
        disabled: true,
      },
    ],
  },
  {
    name: "Information transformation",
    group: "transform",
    entities: [
      {
        name: "Изменение отправителя",
        nodeType: "ChangeOriginator",
        disabled: true,
      },
      {
        name: "Своё изменение",
        nodeType: "TransformationScript",
        disabled: true,
      },
      {
        name: "Отправка на эл. почту",
        nodeType: "RelatedAttributes",
        disabled: true,
      },
    ],
  },
  {
    name: "Actions",
    group: "actions",
    entities: [
      {
        name: "rpc.request.name",
        nodeType: "RPCrequest",
        disabled: false,
      },
      {
        name: "alarm.alarm.name",
        nodeType: "Alarm",
        disabled: false,
      },
      {
        name: "alarm.create.name",
        nodeType: "CreateAlarm",
        disabled: true,
      },
      {
        name: "alarm.test.name",
        nodeType: "TestAlarm",
        disabled: false,
      },
      {
        name: "scada.name",
        nodeType: "PrepairToScada",
        disabled: false,
      },
      {
        name: "Снять тревогу",
        nodeType: "ClearAlarm",
        disabled: true,
      },
      {
        name: "Задержка",
        nodeType: "Delay",
        disabled: true,
      },
      {
        name: "Генератор",
        nodeType: "Generator",
        disabled: true,
      },
      {
        name: "Логирование",
        nodeType: "Log",
        disabled: true,
      },
      {
        name: "Ответ на RPCrequest-вызов",
        nodeType: "RPCreply",
        disabled: true,
      },
      {
        name: "Сохранение атрибутов",
        nodeType: "SaveAttributes",
        disabled: true,
      },
      {
        name: "Сохранение временных рядов",
        nodeType: "SaveTimeSeries",
        disabled: true,
      },
      {
        name: "Сохранение в базу данных",
        nodeType: "SaveToDB",
        disabled: true,
      },
      {
        name: "Назначить клиента",
        nodeType: "AssignToCustomer",
        disabled: true,
      },
      {
        name: "Снять назначение клиента",
        nodeType: "UnassignFromCustomer",
        disabled: true,
      },
      {
        name: "Создать отношение",
        nodeType: "CreateRelation",
        disabled: true,
      },
      {
        name: "Удалить отношение",
        nodeType: "RemoveRelation",
        disabled: true,
      },
      {
        name: "Событие GPS-геозоны",
        nodeType: "GeofencingEvent",
        disabled: true,
      },
      {
        name: "Отправить в облако",
        nodeType: "PushToCloud",
        disabled: true,
      },
      {
        name: "Отправить в периферию",
        nodeType: "PushToEdge",
        disabled: true,
      },
    ],
  },
  {
    name: "Output nodes",
    group: "output",
    entities: [
      {
        name: "bacnet.write.name",
        nodeType: "BACnetWrite",
        disabled: true,
      },
      {
        name: "mqtt.out.name",
        nodeType: "MQTT",
        disabled: false,
      },
      {
        name: "tg.name",
        nodeType: "Telegram",
        disabled: false,
      },
      {
        name: "http.out.name",
        nodeType: "RESToutput",
        disabled: false,
      },
      {
        name: "WebSocket",
        nodeType: "WS",
        disabled: true,
      },
      {
        name: "Kafka",
        nodeType: "Kafka",
        disabled: true,
      },
      {
        name: "Email",
        nodeType: "ToEmail",
        disabled: true,
      },
      {
        name: "SMS",
        nodeType: "RelatedAttributes",
        disabled: true,
      },
      {
        name: "fw.name",
        nodeType: "FW",
        disabled: false,
      },
    ],
  },
  {
    name: "Debug nodes",
    group: "debug",
    entities: [
      {
        name: "debug.console",
        nodeType: "ConsoleLoger",
        disabled: false,
      },
      {
        name: "debug.debug",
        nodeType: "Debug",
        disabled: false,
      },
      {
        name: "debug.note.name",
        nodeType: "Note",
        disabled: false,
      },
    ],
  },
];

