import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import { RiMenuAddLine } from "react-icons/ri";

function CustomerAttributes({
  data,
  selected,
}: {
  data: any;
  selected: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [metadata_key, setMeta] = React.useState("metadataKey");
  const [key, setKey] = React.useState("someField");

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Customer attributes</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<RiMenuAddLine />}
          onClick={onOpen}
        />
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customer attributes settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Metadata key</FormLabel>
              <Input
                type="text"
                value={metadata_key}
                onChange={(e) => setMeta(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Simple field key</FormLabel>
              <Input
                type="text"
                value={key}
                onChange={(e) => setKey(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default CustomerAttributes;
