import { Node, XYPosition } from "react-flow-renderer";
import useStore, { Chain, NodeData } from "../store/store";


export default function useServer() {
  const {
    API,
    chainList,
    currentChain,
    setChainList,
    setLastId
  } = useStore()
  const id = useStore((state) => state.lastId)
  /**
 * Создает новую цепочку и кидает запрос на сервер. Созданная цепочка добавляется в chainList
 * @param name имя цепочки
 * @param currentJSON текущий JSON цепочки
 * @returns измененный список ключей с новой цепочкой
 */
  async function createChain(name: string, chain: Chain): Promise<string[] | undefined> {
    if (name === "") {
      throw new Error('Ошибка: нужно ввести имя')
    }
    if (chainList.find((chain) => chain.name === name)) {
      throw new Error('Ошибка: имя уже занято')
    }

    const tmp = {
      ...chain,
      name,
    };
    tmp.nodes.forEach((node) => {
      // для сервера оставить
      if (node.data.settings) {
        //@ts-ignore
        node.settings = node.data.settings;
        delete node.data.settings;
      }
      //@ts-ignore
      node.data.id = Number(node.id)
    })
    return fetch(API, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(tmp)
    }).then((response) => {
      if (response.ok) {
        const newCh = [...chainList, { name, ...chain }]
        setChainList(newCh)
        const keys: string[] = [];  //Список с именами цепочек (кроме удаляемой)
        for (const key of chainList.values()) {
          keys.push(key.name)
        }
        return keys;
      } else {
        console.error("ОШИБКА http: " + response.status)
        return undefined;
      }
    })
      .catch((error: any) => {
        if (error instanceof TypeError)
          console.error('Ошибка:', error);
        return undefined;
      })
  }

  /**
   * Удаляет цепочку с сервера
   * @param name имя удаляемой цепочки
   * @returns список без удаленной цепочки
   */
  async function deleteChain(name: string): Promise<string[] | undefined> {
    if (name === "") {
      throw new Error('Ошибка: нужно ввести имя')
    }
    return fetch(API, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify({ name })
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        console.log("Успешно удалена цепочка ", name)
        const newCh = chainList.filter((chain) => chain.name !== name)
        setChainList(newCh)
        let keys: string[] = [];  //Список с именами цепочек (кроме удаляемой)
        for (let key of chainList.values()) {
          keys.push(key.name)
        }
        return keys;
      } else {
        console.error("ОШИБКА http: " + response.status)
        return undefined;
      }
    }).catch((error: any) => {
      if (error instanceof TypeError)
        console.error('Ошибка:', error);
      return undefined;
    })
  }
  /**
   * Получает список цепочек
   * @returns 
   */
  async function getChains() {
    if (process.env.NODE_ENV === 'development' && process.env.FRONT_DEVEL) {
      return new Promise((res, rej) => {
        const newMap = [{
          name: '123',
          "nodes": [
            {
              "width": 192,
              "height": 52,
              "id": "0",
              "type": "http_in",
              "position": {
                "x": 824.25,
                "y": 226.25
              },
              "data": {
                "settings": {
                  "host": "localhost:5555",
                  "port": "5555",
                  "pathname": "/api/pushdata",
                  "content": "application/json",
                  "method": "POST"
                }
              },
              "positionAbsolute": {
                "x": 824.25,
                "y": 226.25
              },
              "selected": true
            },
            {
              "width": 272,
              "height": 52,
              "id": "1",
              "type": "ModbusRTU",
              "position": {
                "x": 677.75,
                "y": 338.75
              },
              "data": {
                "settings": ""
              },
              "positionAbsolute": {
                "x": 677.75,
                "y": 338.75
              },
              "selected": false,
              "dragging": false
            }
          ],
          "edges": [],
          "viewport": {
            "x": -1007.5,
            "y": -185.5,
            "zoom": 2
          }
        }, {
          name: 'zxczx',
          "nodes": [
            {
              "width": 192,
              "height": 52,
              "id": "0",
              "type": "http_in",
              "position": {
                "x": 824.25,
                "y": 226.25
              },
              "data": {
                "settings": {
                  "host": "localhost:5555",
                  "port": "5555",
                  "pathname": "/api/pushdata",
                  "content": "application/json",
                  "method": "POST"
                }
              },
              "positionAbsolute": {
                "x": 824.25,
                "y": 226.25
              },
              "selected": true
            },
            {
              "width": 272,
              "height": 52,
              "id": "1",
              "type": "ModbusRTU",
              "position": {
                "x": 677.75,
                "y": 338.75
              },
              "data": {
                "settings": ""
              },
              "positionAbsolute": {
                "x": 677.75,
                "y": 338.75
              },
              "selected": false,
              "dragging": false
            }
          ],
          "edges": [],
          "viewport": {
            "x": -1007.5,
            "y": -185.5,
            "zoom": 2
          }
        }]
        setChainList(newMap)
      })
    }
    return fetch(API, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        console.log("Успешно получены цепочки")
        response.json().then((result: string[]) => {
          const newMap = result ? result.map((res) => {
            const final = JSON.parse(res)
            final.nodes.forEach((node: any) => {
              // для сервера оставить
              if (node.settings) {
                node.data.settings = node.settings;
                delete node.settings;
              }
              delete node.data.id;
            })
            return final
          }) : []
          setChainList(newMap)
        })
        return;
      } else {
        // alert("ОШИБКА http: " + response.body)
        console.error("ОШИБКА http: " + response.status)
        return undefined;
      }
    }).catch((error: any) => {
      if (error instanceof TypeError)
        console.error('Ошибка:', error);
      return undefined;
    })
  }
  /**
   * Меняет цепочку на сервере
   * @param name имя цепочки
   * @param chain собственно сама цепочка
   * @returns 
   */
  async function patchChain(
    name: string,
    chain: Chain,
  ): Promise<string[] | undefined> {
    if (name === "") {
      throw new Error('Ошибка: отредактировать можно только существующую цепочку')
    }

    const tmp = { ...chain, name };

    return fetch(API, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(tmp)
    }).then((response) => {
      if (response.ok) {
        const newCh = chainList.map((chain) =>
          chain.name === name ? currentChain : chain
        )
        setChainList(newCh)
        let keys: string[] = [];
        for (let key of chainList.values()) {
          keys.push(key.name)
        }
        return keys;
      } else {
        console.error("ОШИБКА http: " + response.status)
        return undefined;
      }
    })
      .catch((error: any) => {
        console.error('Ошибка:', error);
        return undefined;
      })
  }

  function makeFlowNode(
    type: string,
    position: XYPosition
  ): Node | undefined {
    if (type === "") {
      return;
    }
    if (position === undefined) {
      position = { x: 0, y: 0 };
    }
  

    const newNode: Node<NodeData> = {
      id,
      type: "",
      position,
      data: {
        settings: {}
      },
    };
    setLastId((Number(id) + 1).toString());
    
  
    switch (type) {
      case "HTTPInput":
        newNode.type = "http_in";
        break;
      case "WebSocketLoger":
        newNode.type = "outputNode";
        break;
      case "Mercury":
        newNode.type = "mercury";
        break;
      case "ModbusTCP":
        newNode.type = "modbusTCP";
        newNode.data.json = {}
        break;
      case "KNXIP":
        newNode.type = type;
        
        break;
      case "Schedule":
        newNode.type = "shedule";
        
        break;
      case "MQTT":
        newNode.type = "mqtt";
        break
      case "Telegram":
        newNode.type = "telegram";
        
        break;
      case "WS":
        newNode.type = "ws";
        
        break;
      case "Debug":
        newNode.type = "debug";
        newNode.data.json = {};
        break;
      case "Note":
        newNode.type = "note";
        
        break;
      case "FW":
        newNode.type = "FW";
        
        break;
      case "BACnetRead":
        newNode.type = "bacnetread";
        
        break;
      case "BACnetReadMultiply":
        newNode.type = "bacnetreadmultiply";
        
        break;
      case "BACnetWrite":
        newNode.type = "bacnetwrite";
        
        break;
      case "BACnetWhoIs":
        newNode.type = "bacnetwhois";
        
        break;
  
      case "BACnetCOV":
        newNode.type = "bacnetcov";
        
        break;
  
      case "RESToutput":
        newNode.type = "RESToutput";
        
        break;
      case "TestAlarm":
        newNode.type = "TestAlarm";
        
        break;
      case "ConsoleLoger":
        newNode.type = "ConsoleLoger";
        
        break;
      case "DatatypeFilter":
        newNode.type = "DatatypeFilter";
        
        break;
      case "PrepairToScada":
        newNode.type = "PrepairToScada";
        
        break;
      case "CreateAlarm":
        newNode.type = "CreateAlarm";
        break;

      case "Alarm":
        newNode.type = "Alarm";
        break;

      case "Rubicon":
        newNode.type = "Rubicon";
        
        break;
      case "SNMPinput":
        newNode.type = "SNMPinput";
        
        break;
      case "ModbusRTU":
        newNode.type = "ModbusRTU";
        
        break;
      case "MQTTInput":
        newNode.type = "MQTTInput";
        
        break;
      case "RPCrequest":
        newNode.type = "RPCrequest";
        
        break;
  
      //Заглушки
      case "RPCreply":
        newNode.type = "RPCreply";
        
        break;
      case "SaveAttributes":
        newNode.type = "SaveAttributes";
        
        break;
      case "SaveTimeSeries":
        newNode.type = "SaveTimeSeries";
        
        break;
      case "CalculateDelta":
        newNode.type = "CalculateDelta";
        
        break;
      case "CustomerAttributes":
        newNode.type = "CustomerAttributes";
        
        break;
      case "DeviceAttributes":
        newNode.type = "DeviceAttributes";
        
        break;
      case "OriginatorAttributes":
        newNode.type = "OriginatorAttributes";
        
        break;
      case "RelatedAttributes":
        newNode.type = "RelatedAttributes";
        
        break;
      case "CheckRelation":
        newNode.type = "CheckRelation";
        
        break;
      case "FiledExist":
        newNode.type = "FiledExist";
        
        break;
      case "MessadgeType":
        newNode.type = "MessadgeType";
        
        break;
      case "MessadgeTypeSwitch":
        newNode.type = "MessadgeTypeSwitch";
        
        break;
      case "OriginatorType":
        newNode.type = "OriginatorType";
        
        break;
      case "OroginatorTypeSwitch":
        newNode.type = "OroginatorTypeSwitch";
        
        break;
      case "ScriptFilter":
        newNode.type = "ScriptFilter";
        
        break;
      case "Kafka":
        newNode.type = "Kafka";
        
        break;
      case "ChangeOriginator":
        newNode.type = "ChangeOriginator";
        
        break;
      case "ToEmail":
        newNode.type = "ToEmail";
        
        break;
      case "TransformationScript":
        newNode.type = "TransformationScript";
        
        break;
      case "AssignToCustomer":
        newNode.type = "AssignToCustomer";
        
        break;
      case "ClearAlarm":
        newNode.type = "ClearAlarm";
        
        break;
  
      case "CreateRelation":
        newNode.type = "CreateRelation";
        
        break;
      case "Delay":
        newNode.type = "Delay";
        
        break;
      case "Generator":
        newNode.type = "Generator";
        
        break;
      case "GeofencingEvent":
        newNode.type = "GeofencingEvent";
        
        break;
      case "Log":
        newNode.type = "Log";
        
        break;
      case "PushToCloud":
        newNode.type = "PushToCloud";
        
        break;
      case "PushToEdge":
        newNode.type = "PushToEdge";
        
        break;
      case "RemoveRelation":
        newNode.type = "RemoveRelation";
        
        break;
      case "SaveToDB":
        newNode.type = "SaveToDB";
        
        break;
      case "UnassignFromCustomer":
        newNode.type = "UnassignFromCustomer";
        
        break;
  
      default:
        break;
    }
  
    return newNode;
  }

  return {
    createChain,
    deleteChain,
    getChains,
    patchChain,
    makeFlowNode
  };
}

