import { progressAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  track:{
    borderRadius: "5%"
  },
  filledTrack: {
    transition: "all 0.2s ease"
  }
});

export const progressTheme = defineMultiStyleConfig({
  baseStyle
});
