import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import * as images from "../images/guide";
import { useTranslation } from "react-i18next";

function Guide({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const [currentPage, setCurrentPage] = useState(0);

  const { t } = useTranslation();

  const guideSteps = [
    {
      title: t('guide.0.title'),
      text: t('guide.0.text'),
      img: images.step0,
    },
    {
      title: t('guide.1.title'),
      text: t('guide.1.text'),
      img: images.step1,
    },
    {
      title: t('guide.2.title'),
      text: t('guide.2.text'),
      img: images.step2,
    },
    {
      title: t('guide.3.title'),
      text: t('guide.3.text'),
      img: images.step3,
    },
    {
      title: t('guide.4.title'),
      text: t('guide.4.text'),
      img: images.step3,
    },
  ];

  const handleClose = () => {
    onClose();
    setCurrentPage(0);
  };
  return (
    <>
      <Modal variant={"guide"} isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent h={{ base: "35em", md:"45em" }} m={{ base: 0, md: 2, lg: 4}}>
          <ModalHeader mb={3}>{guideSteps[currentPage].title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={guideSteps[currentPage].img} alt={guideSteps[currentPage].title} />
            <Text mt={8}>{guideSteps[currentPage].text}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setCurrentPage(currentPage - 1)}
              isDisabled={currentPage < 1}
            >
              {t('Back')}
            </Button>
            <Progress 
              width={{ base: 100 ,md: 225}}
              value={currentPage + 1}
              max={guideSteps.length}
              min={0}
            />
            {currentPage === guideSteps.length - 1 ? (
              <Button colorScheme="blue" onClick={handleClose}>
                {t('Finish')}
              </Button>
            ) : (
              <Button onClick={() => setCurrentPage(currentPage + 1)}>
                {t('Next')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Guide;
