import React from "react";
import {  NodeProps } from "react-flow-renderer";
import { useColorModeValue } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { VscDebug } from "react-icons/vsc";
import ReactJson from "react-json-view";
import {
  darkOceanEdited,
  invertedOceanEdited,
} from "../styles/componentsStyles";
import DebugNode from "./types/DebugNode";
import { useTranslation } from "react-i18next";
import { NodeData } from "../store/store";

function Debug({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const jsonTheme = useColorModeValue(invertedOceanEdited, darkOceanEdited);
  const { t } = useTranslation();
  const label = t('debug.debug')

  return (
    <DebugNode label={label} selected={selected} onOpen={onOpen} icon={<VscDebug />}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <ReactJson src={data.json} collapsed={true} theme={jsonTheme} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </DebugNode>
  );
}

export default Debug;
