import React from "react";
import Block from "../../components/Block";
import { Handle, Position } from "react-flow-renderer";
import { Button, HStack } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

interface WorkerNodeProps {
  selected: boolean,
  label: string,
  disabled?: boolean,
  onOpen?: () => void,
  children? : React.ReactNode
}

export default function WorkerNode (props: WorkerNodeProps) {
  const { selected = false, disabled = false, onOpen, label, children } = props
  const style = {
    width: '10px',
    height: '10px',
    border: '1px solid rgb(0, 0, 0)',
    background: '#b1b4be',
    cursor: 'pointer',
  }
  const { t } = useTranslation()

  return (
    <Block variant="worker" selected={selected} disabled={disabled}>
      <Handle
        type="source"
        style={style}
        position={Position.Right}
        id="0"
      />
      <Handle
        type="target"
        style={style}
        position={Position.Left}
        id="0"
      />
      <HStack spacing={2} padding={1} minH={10}>
        <label htmlFor="text">{label}</label>
        {children && 
          <Button
            variant="outline"
            size="sm"
            rightIcon={<VscSettings />}
            onClick={onOpen}
          >
            {t('Settings')}
          </Button>
        } 
        {children}
      </HStack>

    </Block>
  )
}