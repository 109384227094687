import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import { VscCloudUpload } from "react-icons/vsc";

function PushToEdge({ data, selected }: { data: any; selected: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [originator, setOriginator] = React.useState("Device");
  const [type, setType] = React.useState("Post telemetry");

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Push to edge</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<VscCloudUpload />}
          onClick={onOpen}
        />
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Push to edge settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Originator</FormLabel>
              <Input
                type="text"
                value={originator}
                onChange={(e) => setOriginator(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Message type</FormLabel>
              <Input
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default PushToEdge;
