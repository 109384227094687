import {Box, MenuItem, MenuList, Tooltip, useStyleConfig} from "@chakra-ui/react";
import React from "react";
import {ContextMenu} from "chakra-ui-contextmenu";
import { useTranslation } from "react-i18next";

function Block(props: any) {
  const { variant, disabled = false, selected = false, ...rest } = props;

  let finalVariant = disabled ? "disabled" : variant;

  const styles = useStyleConfig("Block", { variant: finalVariant });

  const { t } = useTranslation();

  const label = t('Work in progress')
  // Pass the computed styles into the `__css` prop
  return (
    <>
      {disabled ? (
        <Tooltip label={label}>
          <Box
            cursor={disabled ? "not-allowed" : "grab"}
            boxShadow={selected ? "outline" : "none"}
            __css={styles}
            {...rest}
          />
        </Tooltip>
      ) : (
          // <ContextMenu<HTMLDivElement>
          //     renderMenu={() => (
          //         <MenuList zIndex={1000}>
          //           <MenuItem>{t('context.copy')}</MenuItem>
          //           <MenuItem>{t('context.paste')}</MenuItem>
          //           <MenuItem>{t('context.cut')}</MenuItem>
          //           <MenuItem>{t('context.delete')}</MenuItem>
          //         </MenuList>
          //     )}
          // >
          //  {ref =>
                <Box
                    cursor={disabled ? "not-allowed" : "grab"}
                    boxShadow={selected ? "outline" : "none"}
                    __css={styles}
                    {...rest}
                    />
           //         ref={ref}
         //       />}
        //</>  </ContextMenu>
      )}
    </>
  );
}

export default Block;
