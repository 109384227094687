import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  InputGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Textarea, Text } from "@chakra-ui/react";
import useStore, { NodeData } from "../store/store";
import { useTranslation } from "react-i18next";
import DebugNode from "./types/DebugNode";
import { BsBellFill } from "react-icons/bs";

function TestAlarm({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [msg, setMsg] = useState(
    JSON.stringify({
      name: "ch4-canal1",
      value: "1",
    })
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          msg
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };
  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation();

  const label = t('alarm.test.name')

  return (
    <DebugNode label={label} selected={selected} onOpen={onOpen} icon={<BsBellFill />}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('alarm.test.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup>
              <Text mb="8px">{t('alarm.test.props.0')}</Text>
              <Textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                size="sm"
              />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DebugNode>
  );
}

export default TestAlarm;
