import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ReactJson from "react-json-view";
import {
  darkOceanEdited,
  invertedOceanEdited,
} from "../styles/componentsStyles";
import InputNode from "./types/InputNode";
import { useTranslation } from "react-i18next";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import useStore, { NodeData } from "../store/store";

function SNMPinput({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [settings, setSettings] = useState<object>(
    data.settings !== undefined ? data.settings.settings : {}
  );

  const jsonTheme = useColorModeValue(invertedOceanEdited, darkOceanEdited);

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = settings
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t }= useTranslation();
  const label = t('snmp.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('snpm.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('snmp.props.0')}</FormLabel>
              <Input
                type="file"
                accept=".json"
                onChange={(e) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    const tmp = reader.result!.toString();
                    setSettings(JSON.parse(tmp));
                  };
                  reader.readAsText(e.target.files?.item(0)!);
                }}
              />
            </FormControl>
            <ReactJson src={settings} collapsed={true} theme={jsonTheme} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}

export default SNMPinput;
