import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Heading,
  Input,
  List,
  ListItem,
  OrderedList,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import {
  darkOceanEdited,
  invertedOceanEdited,
} from "../styles/componentsStyles";
import useServer, {
} from "../utils/useServer";
import SidebarHeader from "./SidebarHeader";
import useStore from "../store/store";
import { allNodes } from "../constants";
import Block from "./Block";
import { useTranslation } from "react-i18next";
import { useReactFlow } from "react-flow-renderer";

function LeftSidebar({
  isOpen,
  onClose,
  onModalOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onModalOpen: () => void;
}) {
  const {
    blockDisabled,
    currentChain,
    setCurrentChain,
    chainList,
    setChainList,
    setLastId
  } = useStore();

  const listRef = useRef<null | HTMLOListElement>(null);
  const input = useRef<null | HTMLInputElement>(null);
  const [name, setName] = useState(currentChain.name)

  // примеры тем https://mac-s-g.github.io/react-json-view/demo/dist/, можно сделать свою тему, используя base16 цвета
  const jsonTheme = useColorModeValue(invertedOceanEdited, darkOceanEdited);
  const instance = useReactFlow();
  const { t } = useTranslation();
  const {
    createChain,
    deleteChain,
    getChains,
    patchChain,
  } = useServer();
  const json = instance.toObject();

  useEffect(() => {
    GetChains();
  }, []);

  const haveAnyNodes = instance.getNodes().length > 0; // имеем ли вообще какие-то узлы, может быть что-то не нужно ттображать....

  async function CreateChain() {
    await createChain(name, json);
  }

  async function GetChains() {
    await getChains();
  }

  async function PatchChain() {
    await patchChain(currentChain.name, json);
  }

  async function DeleteCurrentChain() {
    await deleteChain(currentChain.name);
  }
  function Reset() {
    instance.setNodes([]);
    instance.setEdges([]);
    setName("")
  }

  function SaveAsFile() {
    let tmp = { ...json, name };
    tmp.nodes.forEach((node: any) => {
      node.settings = node.data.settings;
      delete node.data.settings
    });
    
    const file = new Blob([JSON.stringify(tmp)], {
      type: 'applecation/json'
    })
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);        
    document.body.appendChild(link);
    
    link.click();
    
    link.parentNode?.removeChild(link);
  } 

  function LoadFromFile(e: ChangeEvent<HTMLInputElement>){
    if (e.target.files !== null){
      const reader = new FileReader()
      reader.onload = (ev) => {
        if (ev.target?.result) {
          const obj = JSON.parse(ev.target?.result as string)
          if (e.target.files !== null) {
            setName(e.target.files[0].name)
            obj.nodes.forEach((node: any) => {
              node.id = node.id.toString();
              if (node.settings != null) {
                node.data.settings = node.settings;
                delete node["settings"];
              }
            })
            const newCh = [...chainList, { name, ...obj }]
            setChainList(newCh)
            instance.setNodes(obj.nodes);
            instance.setEdges(obj.edges);
            instance.setViewport(obj.viewport);
            setLastId((Number(obj.nodes[obj.nodes.length - 1].id) + 1).toString())
            setCurrentChain(obj);
          }
        }
      }
      reader.readAsText(e.target.files[0])
    }
  }

  const list = [];
  for (const chain of chainList.values()) {
    const style = currentChain.name === chain.name ? {
      borderRadius: '5px',
      background: '#dbdce0',
      color: 'black'
    } : {};
    list.push(
    <ListItem
      key={chain.name}
      onClick={() => {
        setCurrentChain(chain)
        instance.setNodes(chain.nodes);
        instance.setEdges(chain.edges);
        instance.setViewport(chain.viewport);
        setLastId((Number(chain.nodes[chain.nodes.length - 1].id) + 1).toString())
        setName(chain.name)
      }}
      style={{ cursor: "pointer", ...style }}
    >
      {chain.name}
    </ListItem>
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      variant="alwaysOpen"      
    >
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <SidebarHeader onModalOpen={onModalOpen} />
        </DrawerHeader>
        <DrawerBody pt={0} 
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-chakra-body-text)',
            borderRadius: '24px',
          },
        }}
        >
            <Tabs variant="line">
              <TabList position="absolute" bg={'var(--drawer-bg)'} left={9} zIndex={1000}>
                <Tab>{t('Chains')}</Tab>
                <Tab>{t('Nodes')}</Tab>
                <Tab>JSON</Tab>
              </TabList>
              <TabPanels pt={10}>
                <TabPanel>
                  <Stack spacing={6} px="24px">
                    <Button colorScheme="blue" onClick={Reset}>
                      {t('Clear field')}
                    </Button>
                    <Divider />
                    <Input
                      value={name}
                      variant="outline"
                      placeholder={(() => {const l = t('Chain name'); return l})()}
                      name="name"
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    {haveAnyNodes 
                    && <Button colorScheme="blue" onClick={CreateChain}>
                      {t('Create chain')}
                    </Button>}
                    <Button colorScheme="blue" onClick={PatchChain}>
                      {t('Save chain')}
                    </Button>
                    <Button colorScheme="red" onClick={DeleteCurrentChain}>
                      {t('Delete chain')}
                    </Button>
                    <Divider />
                    <Button colorScheme="blue" onClick={SaveAsFile}>
                      {t('Save as file')}
                    </Button>
                    <Button colorScheme="blue" onClick={() => {
                      input.current?.click();
                    }}>
                      {t('Load from file')}
                      <Input ref={input} id="load-file" type="file" display="none" onChange={LoadFromFile}/>
                    </Button>
                    <Divider />
                    <Button colorScheme="blue" onClick={() => GetChains()}>
                      {t('Get chains')}
                    </Button>
                  </Stack>
                  <OrderedList spacing={3} ref={listRef} my={3}>
                    {list}
                  </OrderedList>
                </TabPanel>
                <TabPanel>
                  <Accordion defaultIndex={[0, 1, 2, 3, 4, 5, 6]} allowMultiple>
                    {allNodes.map((group) => {
                      return (
                        <AccordionItem key={group.group}>
                          <AccordionButton>
                            <Flex w={"100%"}>
                              <Heading size={"md"} textAlign="left">{t(`${group.name}`)}</Heading>
                              <Spacer />
                              <AccordionIcon />
                            </Flex>
                          </AccordionButton>

                          <AccordionPanel>
                            {group.entities.map((node) => {
                              let variant = group.group;
                              if (
                                group.group === "filter" ||
                                group.group === "comp" ||
                                group.group === "transform"
                              ) {
                                variant = "worker";
                              } else if (group.group === "actions") {
                                variant = "debug";
                              }
                              return (
                                <Block
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                  }}
                                  variant={variant}
                                  disabled={
                                    blockDisabled ? node.disabled : false
                                  }
                                  data-value={node.nodeType}
                                  draggable={
                                    blockDisabled ? !node.disabled : true
                                  }
                                  onDragStart={(e: DragEvent<HTMLDivElement>) => {
                                    e.dataTransfer!.setData(
                                      'text/plain', 
                                      e.currentTarget!.getAttribute('data-value') as string
                                    )
                                  }}
                                >
                                  {t(node.name)}
                                </Block>
                              );
                            })}
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <ReactJson src={json} collapsed={true} theme={jsonTheme} />
                </TabPanel>
              </TabPanels>
            </Tabs>
        </DrawerBody>
        <List>
          <Box padding={9}> </Box>
          <Box sx={{ pl: 1 }}><Text fontSize='xs'> Конфигуратор RuleEngine v.0.9.5 от 25.05.2023 </Text></Box>
          <Box sx={{ pl: 1 }}><Text fontSize='xs'> PromUC Core v.0.1.87 от 25.05.2023 </Text></Box>
        </List>
      </DrawerContent>
    </Drawer>
  );
}
export default LeftSidebar;
