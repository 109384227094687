import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import InputNode from "./types/InputNode";
import { useTranslation } from "react-i18next";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import useStore, { NodeData } from "../store/store";

function Mercury({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setJson = useStore((state) => state.setCurrentChain)
  const inst = useReactFlow()

  const [addr, setAddr] = React.useState("");
  const [delay, setDelay] = React.useState(500);
  const [uart, setUart] = React.useState("/dev/ttyUSB0");
  const [boudrate, setBoudrate] = React.useState(57600);
  const [bytesize, setBytesize] = React.useState(16);
  const [timeout, setTimeout] = React.useState(3000);

  const onSave = () => {
    let addr_list: any[] = [];
    addr.split(",").forEach((el) => addr_list.push(parseInt(el)));
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings =      {
          addr: addr_list,
          delay,
          uart,
          boudrate,
          bytesize,
          timeout,
        }
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation();

  const label = t('mercury.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('mercury.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('mercury.props.0')}</FormLabel>
              <Input
                placeholder="11, 45, ..."
                type="text"
                value={addr}
                onChange={(e) => setAddr(e.target.value)}
              />
              <FormLabel>{t('mercury.props.1')}</FormLabel>
              <Input
                type="number"
                value={delay}
                onChange={(e) => setDelay(parseInt(e.target.value))}
              />
              <FormLabel>{t('mercury.props.2')}</FormLabel>
              <Input
                type="text"
                value={uart}
                onChange={(e) => setUart(e.target.value)}
              />
              <FormLabel>{t('mercury.props.3')}</FormLabel>
              <Input
                type="number"
                value={boudrate}
                onChange={(e) => setBoudrate(parseInt(e.target.value))}
              />
              <FormLabel>{t('mercury.props.4')}</FormLabel>
              <Input
                type="number"
                value={bytesize}
                onChange={(e) => setBytesize(parseInt(e.target.value))}
              />
              <FormLabel>{t('mercury.props.5')}</FormLabel>
              <Input
                type="number"
                value={timeout}
                onChange={(e) => setTimeout(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t("Save")}
            </Button>
            <Button onClick={onClose}>{t("Cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}

export default Mercury;
