import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Lamp from "../../components/Lamp";
import InputNode from "../types/InputNode";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import useStore, { NodeData } from "../../store/store";
import { useTranslation } from "react-i18next";

function ModbusTCP({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [ip_addr, setIPaddr] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.ip_addr
      : "192.168.1.121"
  );
  const [port, setPort] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.port
      : 502
  );
  const [register_name, setRName] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.register_name
      : ""
  );
  const [device_name, setDName] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.device_name
      : ""
  );
  const [data_type, setDatatype] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.data_type
      : "INT16"
  );
  const [regAddr, setRegAddr] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.regAddr
      : 0
  );
  const [numOfReg, setNumofReg] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.numOfReg
      : 1
  );
  const [funcCode, setFuncCode] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.funcCode
      : 3
  );
  const [frequency, setFrequency] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.frequency
      : 1000
  );
  const [scale, setScale] = useState(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings !== "string"
      ? data.settings.scale
      : 1
  );
  const [settings, setSettings] = useState<string>(
    data.settings && Object.keys(data.settings).length > 0 && typeof data.settings == "string"
      ? data.settings
      : ""
  );
  const [current_tab, setTab] = useState(1);
  const [csv, setCSV] = useState<string[][]>([]);

  const onSave = () => {
    if (device_name === "" && register_name === "" && current_tab === 1)
      //Это нужно т.к. при рерндере срабатывает этот мето. И если у нас были настройки из файла, то они теряются
      return;
    switch (current_tab) {
      case 1:
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
          if (node.id === id) {
            node.data.settings = {
              ip_addr,
              port,
              device_name,
              register_name,
              regAddr,
              numOfReg,
              funcCode,
              data_type,
              frequency,
              scale,
            }
          }
          return node
        })
        )
        setJson(inst.toObject())
        break;

      case 2:
        inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
          if (node.id === id) {
            node.data.settings = settings
          }
          return node
        })
        )
        setJson(inst.toObject())
        break;
    }
    onClose();
  };

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t('modbus.tcp.name')

  return (
    <InputNode selected={selected} label={label} onOpen={onOpen}>
      <Lamp isWork={data.json ? data.json.status === 'Ok' : false} colors={['#19ff19', 'red']}></Lamp>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('modbus.tcp.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Tabs>
              <TabList>
                <Tab
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  {t("modbus.tcp.tabs.0")}
                </Tab>
                <Tab
                  onClick={() => {
                    setTab(2);
                  }}
                >
                  {t("modbus.tcp.tabs.1")}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t("modbus.tcp.props.0.0")}</FormLabel>
                    <Input
                      type="text"
                      placeholder={(() => { const a = t("modbus.tcp.props.0.0"); return a})()}
                      value={device_name}
                      onChange={(e) => setDName(e.target.value)}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.1")}</FormLabel>
                    <Input
                      type="text"
                      value={ip_addr}
                      onChange={(e) => setIPaddr(e.target.value)}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.2")}</FormLabel>
                    <Input
                      type="number"
                      value={port}
                      onChange={(e) => setPort(parseInt(e.target.value))}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.3")}</FormLabel>
                    <Input
                      type="text"
                      value={register_name}
                      onChange={(e) => setRName(e.target.value)}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.4")}</FormLabel>
                    <Input
                      type="number"
                      value={regAddr}
                      onChange={(e) => setRegAddr(parseInt(e.target.value))}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.5")}</FormLabel>
                    <Input
                      type="number"
                      value={numOfReg}
                      onChange={(e) => setNumofReg(parseInt(e.target.value))}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.6")}</FormLabel>
                    <Input
                      type="number"
                      value={funcCode}
                      onChange={(e) => setFuncCode(parseInt(e.target.value))}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.7")}</FormLabel>
                    <Select
                      name="type"
                      value={data_type}
                      onChange={(e) => setDatatype(e.target.value)}
                    >
                      <option value="INT16">INT16</option>
                      <option value="INT32">INT32</option>
                      <option value="UINT16">UINT16</option>
                      <option value="UINT32">UINT32</option>
                      <option value="FLOAT32">FLOAT32</option>
                      <option value="FLOAT64">FLOAT64</option>
                      <option value="BOOL">BOOL</option>
                    </Select>
                    <FormLabel>{t("modbus.tcp.props.0.8")}</FormLabel>
                    <Input
                      type="number"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                    />
                    <FormLabel>{t("modbus.tcp.props.0.9")}</FormLabel>
                    <Input
                      type="number"
                      value={frequency}
                      onChange={(e) => setFrequency(parseInt(e.target.value))}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <FormControl>
                    <FormLabel>{t("modbus.tcp.props.1.0")}</FormLabel>
                    <Input
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.onload = () => {
                          const tmp = reader.result!.toString();
                          setSettings(tmp);
                          const rows = tmp.split("\n");
                          let elements: string[][] = [];
                          rows.forEach((row) => {
                            const columns = row.split(";");
                            elements.push(columns);
                          });
                          setCSV(elements);
                        };
                        reader.readAsText(e.target.files?.item(0)!);
                      }}
                    />
                  </FormControl>
                  {settings}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t("Save")}
            </Button>
            <Button onClick={onClose}>{t("Cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InputNode>
  );
}

export default ModbusTCP;
