import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";

function OroginatorTypeSwitch({
  data,
  selected,
}: {
  data: any;
  selected: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Originator type switch</label>
        <IconButton
          disabled={true}
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<BsFilter />}
          onClick={onOpen}
        />
      </HStack>
      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default OroginatorTypeSwitch;
