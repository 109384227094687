import React from "react";

import { Handle, Position } from "react-flow-renderer";
import Block from "../../../components/Block";
import { IconButton } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { RiMenuAddLine } from "react-icons/ri";

function RelatedAttributes({
  data,
  selected,
}: {
  data: any;
  selected: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [from, setFrom] = React.useState("From");
  const [entity_types, setEntity] = React.useState("");
  const [source, setSource] = React.useState("");
  const [target, setTarget] = React.useState("");

  const onSave = () => {
    onClose();
  };

  return (
    <Block variant="worker" selected={selected}>
      <HStack spacing={2} padding={1}>
        <label htmlFor="text">Relates attributes</label>
        <IconButton
          aria-label="open debug data"
          variant="outline"
          size="sm"
          icon={<RiMenuAddLine />}
          onClick={onOpen}
        />
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Related attributes settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Direction</FormLabel>
              <Input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Checkbox size="md" colorScheme="blue" defaultChecked>
                Latest telemetry
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel>Source attribute</FormLabel>
              <Input
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Target attribute</FormLabel>
              <Input
                type="text"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Handle
        type="target"
        className="custom-handle"
        position={Position.Left}
        id="0"
      />
      <Handle
        type="source"
        className="custom-handle"
        position={Position.Right}
        id="0"
      />
    </Block>
  );
}

export default RelatedAttributes;
