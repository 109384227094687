import { extendTheme } from "@chakra-ui/react"
import { Block } from "./componentsStyles";
import { modalTheme } from "./guideModal";
import { progressTheme } from "./progress";

const amazingTheme = {
  fonts: {
    body: `'Open Sans', sans-serif`,
    code: `source-code-pro, 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;`
  },
  components:{
    Progress: progressTheme,
    Modal: modalTheme,
    Button: {
      baseStyle:{
        borderRadius: 20,
      },
    },
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
    Block,
  },
}

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme(amazingTheme, {config});

export default theme;
