import React from "react";
import { Handle, Position } from "react-flow-renderer";
import Block from "../components/Block";

function I2_O1({ data, selected }: { data: any; selected: boolean }) {
  return (
    <Block variant="worker" selected={selected}>
      <Handle
        type="target"
        position={Position.Left}
        id="0"
        style={{ top: 15 }}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="0"
        style={{ bottom: 5, top: "auto" }}
      />
      <div>
        <label htmlFor="text">{data.type}</label>
      </div>
      <Handle type="source" position={Position.Right} id="0" />
    </Block>
  );
}

export default I2_O1;
