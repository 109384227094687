import React, { useEffect, useState } from "react";
import { Node, NodeProps, useReactFlow } from "react-flow-renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputLeftAddon,
  InputGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Lamp from "../../components/Lamp";
import OutputNode from "../types/OutputNode";
import useStore, { NodeData } from "../../store/store";
import { useTranslation } from "react-i18next";
//import { currentLink } from "../constants";

export default function MQTT({ selected, id, data }: NodeProps<NodeData>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inst = useReactFlow()
  const setJson = useStore((state) => state.setCurrentChain)

  const [host, setHost] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.host : "mq.4iot.pro"
  );
  const [port, setPort] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.port : "1883"
  );
  const [user, setUser] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.user : "user"
  );
  const [password, setPassword] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.password : "user"
  );
  const [topic, setTopic] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.topic : ""
  );
  const [qos, setQos] = useState(
    data.settings && Object.keys(data.settings).length > 0 ? data.settings.qos : 1
  );

  const onSave = () => {
    inst.setNodes((old: Node<NodeData>[]) => old.map((node) => {
      if (node.id === id) {
        node.data.settings = {
          host,
          port,
          user,
          password,
          topic,
          qos,
        };
      }
      return node
    })
    )
    setJson(inst.toObject())
    onClose();
  };

  const onSettings = () => {
    onOpen();
    setHost(data.settings.host);
    setPort(data.settings.port);
    setUser(data.settings.user);
    setPassword(data.settings.password);
    setTopic(data.settings.topic);
    setQos(data.settings.qos);
  }

  useEffect(() => {
    onSave();
  }, []);

  const { t } = useTranslation()

  const label = t('mqtt.out.name')

  return (
    <OutputNode selected={selected} label={label} onOpen={onSettings}>
      <Lamp isWork={data.json ? data.json.status === 'Ok' : false} colors={['#19ff19', 'red']}></Lamp>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('mqtt.out.head')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('mqtt.out.props.0')}</FormLabel>
              <InputGroup>
                <InputLeftAddon children="tcp://" />
                <Input
                  type="text"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                />
              </InputGroup>
              <FormLabel>{t('mqtt.out.props.1')}</FormLabel>
              <Input
                type="text"
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
              <FormLabel>{t('mqtt.out.props.2')}</FormLabel>
              <Input
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
              <FormLabel>{t('mqtt.out.props.3')}</FormLabel>
              <Input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormLabel>{t('mqtt.out.props.4')}</FormLabel>
              <Input
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
              <FormLabel>{t('mqtt.out.props.5')}</FormLabel>
              <Input
                type="number"
                value={qos}
                onChange={(e) => setQos(parseInt(e.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onSave} mr={3}>
              {t('Save')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </OutputNode>
  );
}
