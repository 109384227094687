const resources = {
  en: {
    translation: {
      context: {
        copy: 'Copy',
        paste: 'Paste',
        cut: 'Cut',
        delete: 'Delete'
      },
      'Boolean': 'Boolean',
      'Threshold': 'Threshold',
      'value': 'value',
      'Min': 'Minimal',
      'Max': 'Maximum',
      'MinMaxValue': 'Value between',
      'True': 'True',
      'False': 'False',
      'Save': 'Save',
      'Add': 'Add',
      'Remove': 'Remove',
      'Allow': 'Allow',
      'Reject': 'Reject',
      'Cancel': 'Cancel',
      'Next': 'Next',
      'Back': 'Back',
      'Finish': 'Finish',
      'Settings': 'Settings',
      "Core's API URI": "Core's API URI",
      "Debug socket's URI": "Debug socket's URI",
      'Block disabled elements': 'Block disabled elements',
      'Language': 'Language',
      'Russian': 'Russian',
      'English': 'English',
      'Chains': 'Chains',
      'Nodes': 'Nodes',
      'Create chain': 'Create chain',
      'Save chain': 'Save chain',
      'Delete chain': 'Delete chain',
      'Get chains': 'Get chains',
      'version': 'version',
      'Chain name': 'Chain name',
      'Input nodes': 'Input nodes',
      'Filters and routing': 'Filters and routing',
      'Adding information': 'Adding information',
      'Information transformation': "Information transformation",
      'Actions': 'Actions',
      'Output nodes': 'Output nodes',
      'Debug nodes': 'Debug nodes',
      'Work in progress': 'Work in progress',
      'Save as file': 'Save as file',
      'Load from file': 'Load from file',
      'Clear field': 'Clear field',
      guide: [
        {
          title: "Welcome to RuleEngine",
          text: 'Here is a small guide to use. Click "Next" to continue and "Back" to return or click on the cross or outside of this window to skip.',
        }, {
          title: "How to interact?",
          text: "In order to interact with the chain constructor, you need to open the menu on the left",
        }, {
          title: "Dark and light themes",
          text: "You can change the theme to light or stay on dark by clicking on the button",
        }, {
          title: "Adding nodes",
          text: 'You can add nodes either by using the drop-down menu and the "Add Node" button, or by dragging elements',
        }, {
          title: "Creating and deleting chains",
          text: 'Chains are saved on the server when you click on the "Create chain" button. Chains are deleted when you click on "Delete"',
        }
      ],
      http: {
        name: 'HTTP Input',
        head: 'HTTP Input Settings',
        props: [
          'Address',
          'Content-type',
          'Method'
        ]
      },
      bacnet: {
        cov: {
          name: 'BACnet COV',
          head: 'BACnet COV settings',
          props: [
            'Settings file (.csv)'
          ]
        },
        rp: {
          name: 'BACnet RP',
          head: 'BACnet RP settings',
          props: [
            'Settings file (.csv)'
          ],
        },
        rpm: {
          name: 'BACnet RPM',
          head: 'BACnet RPM settings',
          props: [
            'Settings file (.csv)'
          ],
        },
        wi: {
          name: 'BACnet Who Is',
        },
        write: {
          name: 'BACnet',
          head: 'BACnet Write settings',
          tabs: [
            'Manual',
            'File'
          ],
          props: [[
            'Device Name',
            'Device Instance',
            'Object Type',
            'Object Instance',
            'Property Name',
            'Property',
            'Priority',
            'Index',
            'Tag',
            'Value'
          ], [
            'Settings file (.csv)'
          ]]
        }
      },
      debug: {
        console: 'Console log',
        debug: 'Debug data',
        note: {
          name: 'Note',
          plh: 'Write note here...',
          props: [
            "Node's name",
            'Note'
          ]
        }
      },
      mqtt: {
        in: {
          name: "MQTT",
          head: "MQTT settings",
          props: [
            'Host URI',
            'Port',
            'User',
            'Password',
            'Topic',
            'QOS'
          ]
        }, 
        out: {
          name: "MQTT",
          head: "MQTT settings",
          props: [
            'Host URI',
            'Port',
            'User',
            'Password',
            'Topic',
            'QOS'
          ]
        }
      },
      modbus:{
        rtu:{
          name: 'Modbus RTU',
          head: 'Modbus RTU settings',
          props: [
            'Settings file (.csv)'
          ]
        },
        tcp:{
          name: 'Modbus TCP',
          head: 'Modbus TCP settings',
          tabs: [
            'Manual',
            'From file'
          ],
          props: [[
            'Device name',
            'Address',
            'Port',
            'Property name',
            "Register's address",
            'Number of register',
            'Function',
            'Data type',
            'Scale',
            'Period, ms',
          ], [
            'Settings file (.csv)'
          ]]
        }
      }, 
      knx: {
        ip: {
          name: 'KNX IP',
          head: 'KNX IP Settings',
          tabs: [
            'Manual',
            'From file'
          ], 
          props: [
            "Device name",
            'IP Address',
            'Port',
            'Group address',
            'Name',
            'White list',
            'Black list',
            'Data type',
            'Scale',
            'Frequency, ms',
            'Settings file (.csv)',
          ]
        }
      }, 
      mercury: {
        name: 'Mercury',
        head: 'Mercury settings',
        props: [
          'Addresses',
          'Delay, ms',
          'Serialport',
          'Baudrate',
          'Byte size',
          'Timeout, ms'
        ]
      }, 
      rubicon: {
        name: 'Rubicon',
        head: 'Rubicon settings',
        props: [
          'Address',
          'Port'
        ]
      }, 
      snmp: {
        name: 'SNMP',
        head: 'SNMP settings',
        props: [
          'Settings file (.json)'
        ]
      },
      fw: {
        name: 'FrameWork',
        head: 'FrameWork write settings',
        props: [
          'URI',
          'Class to add'
        ],
      },
      tg: {
        name: 'Telegram Bot',
        head: 'Telegram bot publication settings',
        props: [
          'Bot ID'
        ]
      },
      filters: {
        schedule: {
          name: 'Schedule',
          head: 'Schedule settings',
          props: [
            'Week',
            'Day',
            'Hours',
            'Minutes',
            'Seconds'
          ]
        },
        datatype: {
          name: 'Datatype filter',
          head: 'Datatype filter settings',
          props: [
            'Filter type',
            'Data types',
            'Selected types:',
          ]
        }
      },
      alarm: {
        create: {
          name: 'Create alarm',
          head: 'Alarm settings',
          props: [
            'Name',
            'Description',
            'Type'
          ]
        },
        alarm: {
          name: 'Conditional alarm',
          head: 'Alarm settings',
          props: [
            'csv'
          ]
        },
        test: {
          name: 'Test alarm',
          head: 'Test alarm settings',
          props: [
            'Alarm message'
          ]
        }
      },
      scada: {
        name: 'Prepair message to SCADA'
      },
      rpc: {
        request: {
          name: 'RPC request'
        }
      }
    }
  },
  ru: {
    translation: {
      context: {
        copy: 'Копировать',
        paste: 'Вставить',
        cut: 'Вырезать',
        delete: 'Удалить'
      },
      'Boolean': 'Логический',
      'Threshold': 'Порог',
      'value': 'значение',
      'Min': 'Минимальное',
      'Max': 'Максимальное',
      'MinMaxValue': 'Значение между',
      'True': 'Истина',
      'False': 'Ложь',
      'Save': 'Сохранить',
      'Add': 'Добавить',
      'Remove': 'Удалить',
      'Allow': 'Разрешить',
      'Reject': 'Отклонить',
      'Next': 'Далее',
      'Back': 'Назад',
      'Finish': 'Готово',
      'Cancel': 'Отмена',
      'Settings': 'Настройки',
      "Core's API URI": 'URI API Ядра',
      "Debug socket's URI": 'URI Дебаг-сокета',
      'Block disabled elements': 'Блокировать отключенные элементы',
      'Language': 'Язык',
      'Russian': 'Русский',
      'English': 'Английский',
      'Chains': 'Цепочки',
      'Nodes': 'Узлы',
      'Create chain': 'Создать цепочку',
      'Save chain': 'Сохранить цепочку',
      'Delete chain': 'Удалить цепочку',
      'Get chains': 'Получить цепочки',
      'version': 'версия',
      'Chain name': 'Имя цепочки',
      'Input nodes': 'Входные узлы',
      'Filters and routing': 'Фильтры и маршрутизация',
      'Adding information': 'Добавление информации',
      'Information transformation': "Преобразование информациии",
      'Actions': 'Действия',
      'Output nodes': 'Выходные узлы',
      'Debug nodes': 'Узлы отладки',
      'Work in progress': 'В разработке',
      'Save as file': 'Сохранить в файл',
      'Load from file': 'Загрузить из файла',
      'Clear field': 'Очистить поле',
      guide: [
        {
          title: "Добро пожаловать в RuleEngine",
          text: 'Здесь представлено небольшое руководство к использованию. Нажмите "Далее" чтобы продолжить и "Назад" чтобы вернуться или нажмите на крестик или вне данного окна чтобы пропустить.',
        }, {
          title: "Как взаимодействовать?",
          text: "Для того, чтобы взаимодействовать с конструктором цепочек, необходимо вызвать меню слева",
        }, {
          title: "Темная и светлая темы",
          text: "Вы можете сменить тему на светлую или остаться на темной по клику на кнопку",
        }, {
          title: "Добавление узлов",
          text: 'Добавить узлы можно как с помощью выпадающего меню и кнопки "Добавить узел", так и с помощью перетаскивания элементов',
        }, {
          title: "Создание и удаление цепочек",
          text: 'Цепочки сохраняются на сервере при клике на кнопку "Создать цепочку". Удаление цепочек происходит при клике на "Удалить"',
        }
      ],
      http: {
        in: {
          name: 'HTTP',
          head: 'Настройки HTTP ввода',
          props: [
            'Адрес',
            'Content-type',
            'Method'
          ]
        },
        out: {
          name: 'HTTP',
          head: 'Настройки HTTP вывода',
          props: [
            'Адрес',
          ]
        }
      },
      bacnet: {
        cov: {
          name: 'BACnet COV',
          head: 'Настройки BACnet COV',
          props: [
            'Файл настроек (.csv)'
          ]
        },
        rp: {
          name: 'BACnet RP',
          head: 'Настройки получаемого свойства BACnet',
          props: [
            'Файл настроек (.csv)'
          ],
        },
        rpm: {
          name: 'BACnet RPM',
          head: 'Настройки повторяющегося свойства BACnet',
          props: [
            'Файл настроек (.csv)'
          ],
        },
        wi: {
          name: 'BACnet WI',
        },
        write: {
          name: 'BACnet',
          head: 'Настройки записи в BACnet',
          tabs: [
            'Ручные',
            'Из файла'
          ],
          props: [[
            'Имя устройства',
            'ID устройства',
            'Тип объекта',
            'ID объекта',
            'Имя свойства',
            'Значение свойства',
            'Приоритет',
            'Индекс',
            'Тег',
            'Значение'
          ], [
            'Файл настроек (.csv)'
          ]]
        }
      },
      debug: {
        console: 'Вывод в консоль',
        debug: 'Данные отладки',
        note: {
          name: 'Заметка',
          plh: 'Напишите сюда заметку...',
          props: [
            'Имя узла',
            'Заметка'
          ]
        }
      },
      mqtt: {
        in: {
          name: "MQTT",
          head: "Настройки MQTT",
          props: [
            'URL хоста',
            'Порт',
            'Пользователь',
            'Пароль',
            'Топик',
            'QOS'
          ]
        }, 
        out: {
          name: "MQTT",
          head: "Настройки MQTT",
          props: [
            'URL хоста',
            'Порт',
            'Пользователь',
            'Пароль',
            'Топик',
            'QOS'
          ]
        }
      },
      modbus:{
        rtu:{
          name: 'Modbus RTU',
          head: 'Настройки Modbus RTU',
          props: [
            'Файл настроек (.csv)'
          ]
        },
        tcp:{
          name: 'Modbus TCP',
          head: 'Настройки Modbus TCP',
          tabs: [
            'Ручные',
            'Из файла'
          ],
          props: [[
            'Имя устройства',
            'Адрес',
            'Порт',
            'Имя свойства',
            'Адрес регистра',
            'Количество регистров',
            'Функция',
            'Тип данных',
            'Масштаб',
            'Период, мс',
          ], [
            'Файл настроек (.csv)'
          ]]
        }
      }, 
      knx: {
        ip: {
          name: 'KNX IP',
          head: 'Настройки KNX IP',
          tabs: [
            'Ручные',
            'Из файла'
          ], 
          props: [
            "Имя устройства",
            'IP Адрес',
            'Порт',
            'Групповой адрес',
            'Имя',
            'Белый список',
            'Черный список',
            'Тип данных',
            'Масштаб',
            'Частота, мс',
            'Файл настроек (.csv)',
          ]
        }
      }, 
      mercury: {
        name: 'Меркурий',
        head: 'Настройки меркурия',
        props: [
          'Адреса',
          'Задержка',
          'Точка подключения',
          'Скорость передачи',
          'Размер байта',
          'Время ожидания, мс'
        ]
      }, 
      rubicon: {
        name: 'Рубикон',
        head: 'Настройки рубикона',
        props: [
          'Адрес',
          'Порт'
        ]
      }, 
      snmp: {
        name: 'SNMP',
        head: 'Настройки SNMP',
        props: [
          'Файл настройки (.json)'
        ]
      },
      fw: {
        name: 'FrameWork',
        head: 'Настройки записи в FrameWork',
        props: [
          'URI',
          'Класс для добавления'
        ],
      },
      tg: {
        name: 'Телеграм бот',
        head: 'Наcтройки публикации в телеграм-бота',
        props: [
          'ID бота'
        ]
      },
      filters: {
        schedule: {
          name: 'Расписание',
          head: 'Настройки расписания',
          props: [
            'Неделя',
            'День',
            'Часы',
            'Минуты',
            'Секунды'
          ]
        },
        datatype: {
          name: 'Наличие типа данных',
          head: 'Настройки фильтрации',
          props: [
            'Тип фильтра',
            'Типы данных',
            'Выбранные типы:',
          ]
        }
      },
      alarm: {
        create: {
          name: 'Вызвать тревогу',
          head: 'Настройки тревоги',
          props: [
            'Имя',
            'Описание',
            'Тип'
          ]
        },
        alarm: {
          name: 'Тревога по условию',
          head: 'Настройки тревоги',
          props: [
            'csv'
          ]
        },
        test: {
          name: 'Тестовая тревога',
          head: 'Настройки тестовой тревоги',
          props: [
            'Сообщение тревоги'
          ]
        }
      },
      scada: {
        name: 'Подготовить сообщение для SCADA'
      },
      rpc: {
        request: {
          name: 'RPC запрос'
        }
      }
    }
  }
}
export default resources;